.donorwallblock {
  background-color: $tn-green ;

  &.nm {
    background-color: $teal;
      
    .donor-wall-container #vdonor li{
      background-color: $dark-blue;
    }
  }

  &.sierra {
    background-color: $sds-bright-blue;
      
    .donor-wall-container #vdonor li{
      background-color: #12547D;
    }
  }

  .donor-wall-container {
    position: relative;
    overflow: hidden;

    // .donor-wall-title
    .donor-wall-title {
        color: white;
        position: absolute;
        top: -55px;
        left: -11px;
        text-transform: uppercase;

        @include media('<=950px') {
            position: relative;
            top: inherit;
            left: inherit;
        }
    }

    .donor-search {
        max-width: 1300px;
        margin: 0 auto;
        padding-top: 12%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $dark-blue;


        @include media('<=950px') {
            flex-direction: column;
        }

        p {
            margin: 0;
            font-family: 'Source Sans Pro';
            font-weight: 700;
            font-size: 36px;

            @include media('<=850px') {
                text-align: center;
                padding-bottom: 15px;
            }
        }

        label {
            padding-right: 22px;
            font-family: 'Source Sans Pro';
            font-weight: 700;
            font-size: 24px;
        }

        input {
            padding: 10px;
            border-radius: 8px;
            border: none;
            -webkit-appearance: none;
            appearance: none;
        }
    }

    .list-container {
        margin: 40px 0 130px;
    }


    #vdonor{
        //list-style: none;
        margin: 0 -7px 0 -7px;
        padding: 0;
        //width: 100%;
        //max-width: 1300px;
        //display: flex;
        //justify-content: center;


        @include media('<=850px') {
            border-radius: 12px;
            padding: 20px;
            background-color: #608E33;
            flex-direction: column;
        }
    }

    #vdonor li{
        display: inline-flex;
        width: calc(33% - 14px);
        padding: 15px 35px 20px 35px;
        margin: 0 7px;
        //position: relative;
        background-color: #608E33;
        //border-radius: 12px;
        //flex-direction: column;
        color: white;

        @include media('<=850px') {
            padding: 0 15px 0;
            width: 100%;
        }

        &:not(:last-child) {
            //margin-right: 33px;

            @include media('<=850px') {
                //margin-right: 0;
                margin-bottom: 15px;
            }
        }

        &:nth-child(-n + 3),
        &.topborder {
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
        }

        &:nth-last-child(-n + 3),
        &.bottomborder {
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
        }

        a {
            padding-bottom: 7px;
            color: #fff;
            display: inline-block;
            text-decoration: none;
            font-weight: 600;
            text-transform: uppercase;
            background: linear-gradient(#fff, #fff) bottom/0% 3px no-repeat;
            background-size: 0% 2px;
            transition: 0.5s all;

            &:hover {
                background-size: 100% 3px;
            }
        }
    }
  
  }
}