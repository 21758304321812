.quote {
    position: relative;
    overflow: hidden;

    &::before {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        background-image: url('../../assets/images/People-Solid.svg');
        background-repeat: no-repeat;
        position: absolute;
        right: -225px;
        top: 50%;
        transform: translateY(-50%);
        background-size: 100% auto;
        width: 450px;
        height: 410px;
        opacity: 0.4;

        @include media('<=tablet') {
            width: 200px;
            height: 200px;
            bottom: -175px;
            top: unset;
        }
        @media (max-width:1000px){
          padding-left:1em;
          padding-right:1em;
        }
    }

    .quote-container {
        margin: 0 auto;
        padding: 88px 0;
        max-width: 1000px;

        @include media('<=tablet') {
            padding: 50px 5%;
        }

        blockquote {
            color: white;
            margin: 0;

            p {
                @extend .heading-sm--bold;
                font-weight: 600;
                line-height: 1.5;
            }

            footer {
                display: flex;
                flex-direction: column;
                text-align: right;

                @include media('<=tablet') {
                    text-align: left;
                }

                strong {
                    font-size: 1.5rem;
                }

                cite {
                    font-style: inherit;
                }
            }
        }
    }
}