@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"
// "\23f8" outputs ascii character "⏸"
// "\25b6" outputs ascii character "▶"

$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-pause-character: "\23f8" !default;
$slick-play-character: "\25b6" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }
    @else {
        @return url($slick-loader-path + $url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    }
    @else {
        @return url($slick-font-path + $url);
    }
}

/* Slider */

.slick-list {
    .slick-loading & {
        background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
    }
}

/* Icons */
@if $slick-font-family == "slick" {
    @font-face {
        font-family: "slick";
        src: slick-font-url("slick.eot");
        src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
        font-weight: normal;
        font-style: normal;
    }
}

/* Arrows */

.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;

    &:hover, &:focus {
        .slick-prev-icon, .slick-next-icon {
            &:before {
                opacity: $slick-opacity-on-hover;
            }
        }
    }

    &.slick-disabled {
        cursor: default;

        .slick-prev-icon, .slick-next-icon {
            &:before {
                opacity: $slick-opacity-not-active;
            }
        }
    }

    .slick-prev-icon, .slick-next-icon {
        &:before {
            font-family: $slick-font-family;
            font-size: 20px;
            line-height: 1;
            color: $slick-arrow-color;
            opacity: $slick-opacity-default;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }
}

.slick-prev {
    left: -25px;
    [dir="rtl"] & {
        left: auto;
        right: -25px;
    }

    .slick-prev-icon {
        &:before {
            content: $slick-prev-character;
            [dir="rtl"] & {
                content: $slick-next-character;
            }
        }
    }
}

.slick-next {
    right: -25px;
    [dir="rtl"] & {
        left: -25px;
        right: auto;
    }

    .slick-next-icon {
        &:before {
            content: $slick-next-character;
            [dir="rtl"] & {
                content: $slick-prev-character;
            }
        }
    }
}

/* Dots */

.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: -25px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    li {
        position: relative;
        display: inline-block;
        height: 20px;
        width: 20px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
        button {
            border: 0;
            background: transparent;
            display: block;
            height: 20px;
            width: 20px;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 5px;
            cursor: pointer;
            &:hover, &:focus {
                outline: none;
                .slick-dot-icon {
                    opacity: $slick-opacity-on-hover;
                }
            }
            .slick-dot-icon {
                color: $slick-dot-color;
                opacity: $slick-opacity-not-active;
                &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    content: $slick-dot-character;
                    width: 20px;
                    height: 20px;
                    font-family: $slick-font-family;
                    font-size: $slick-dot-size;
                    line-height: 20px;
                    text-align: center;
                }
            }
        }
        &.slick-active button .slick-dot-icon {
            color: $slick-dot-color-active;
            opacity: $slick-opacity-default;
        }
    }
}

/**
 Improved .sr-only class by ffoodd: https://gist.github.com/ffoodd/000b59f431e3e64e4ce1a24d5bb36034
 */
.slick-sr-only {
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
	-webkit-clip-path: inset(50%) !important;
		clip-path: inset(50%) !important;  /* 2 */
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;            /* 3 */
}

.slick-autoplay-toggle-button {
    position: absolute;
    left: 5px;
    bottom: -25px;
    z-index: 10;

    opacity: $slick-opacity-default;
    background: none;
    border: 0;
    cursor: pointer;

    &:hover, &:focus {
        outline: none;
        opacity: $slick-opacity-on-hover;
    }

    .slick-pause-icon:before {
        content: $slick-pause-character;
        width: 20px;
        height: 20px;
        font-family: $slick-font-family;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
    }

    .slick-play-icon:before {
        content: $slick-play-character;
        width: 20px;
        height: 20px;
        font-family: $slick-font-family;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
    }
}