.community{

  &.sierra {

    .carousel-btn {

      @include media('<=900px') {

        background-color: #00A6DF;

      }
    }

    .community-slider .slick-slide.slick-current.slick-active,
    .community-slider .slick-slide {
      background-color: #00A6DF;
    }

    .community-nav .slick-prev-icon::before,
    .community-nav .slick-next-icon::before {
      color:#00A6DF;
    }

    .quote-container {
      background-color: #00A6DF;
    }

    .thumbnail-container {
      border: 1px solid #9EACB5;

      img {
        border: 1px solid #9EACB5;
      }
    }
  }

  &.nm {
    .carousel-btn {

      @include media('<=900px') {

        background-color: $teal-blue;

      }
    }

    .community-slider .slick-slide.slick-current.slick-active,
    .community-slider .slick-slide {
      background-color: $teal-blue;
    }

    .community-nav .slick-prev-icon::before,
    .community-nav .slick-next-icon::before {
      color: $teal-blue;
    }

    .quote-container {
      background-color: $teal-blue;
    }

    .thumbnail-container {
      border: 1px solid $pale-peach;

      img {
        border: 1px solid $pale-peach;
      }
    }
  }

  .js .community-slider > div:nth-child(1n+2) { display: none }

  .js .community-slider.slick-initialized > div:nth-child(1n+2) { display: block }

  .community-container {
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: 1fr 1fr auto auto;
    grid-row-gap: 1.5em;
    grid-column-gap: 75px;


    @include media('<=900px') {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  .community-slider {
    display: block;
    grid-column: 1;
    grid-row: 1 / span 4;
    margin-bottom: 0px;

    @include media('<=900px') {
      order: 1;
    }

    .slick-list, 
    .slick-track,
    .slick-slide {
      height: 100% !important;
    }

    .community-side-container {
      display: flex !important;
      flex-flow: column;
      min-height: 100%;

      @include media('<=900px') {
        display: inherit !important;
      }
    }
  }

  .slick-slide img {
    width: 100%;
    //height: 75vh;
    object-fit: cover;

    @include media('<=900px') {
      width: 100%;
      object-fit: cover;
      height: 400px;
    }
  }

  .community-image {
    position: relative;
    overflow: hidden;
    //height: 75vh;

    img {
      max-height: 500px;
      height: 500px;
    }


    @include media('<=900px') {
      height: auto;
    }
  }

  .carousel-btn {
    position: absolute;
    content: '';
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 250px;
    height: 120px;
    background-color: white;
    display: inline-block;
    border-top-left-radius: 125px;
    border-top-right-radius: 125px;

    @include media('<=900px') {
      position: inherit;
      padding-top: 30px;
      width: 100%;
      height: inherit;
      background-color: #AACA6F;
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
    }

    a {
      @extend .p-large;
      width: inherit;
      height: inherit;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      color: $dark-blue;
      text-decoration:  none;
      position: relative;
      z-index: 3;

      @include media('<=900px') {
        flex-direction: row;
      }

      &::before {
          position: absolute;
          content: '';
          background-image: url('../../assets/images/cropped-people-circle.svg');
          background-repeat: no-repeat;
          width: 300px;
          height: 250px;
          background-size: contain;
          opacity: 0.4;
          z-index: -7;

          @include media('<=1000px') {
              background-size: 100% auto;
              width: 300px;
              height: 180px;
              top: -30px;
          }

          @include media('<=900px') {
            background-image: unset;
            width: inherit;
            height: inherit;
          }
      }

      span:first-child {
          @extend .p-small;
          margin-top: 25px;
          font-weight: 400;

          @include media('<=900px') {
            margin-top: 0;
            font-family: $f-primary;
            font-size: 16px;
            font-weight: 800;
          }

      }

      span:last-child {
          //margin-top: 25px;
          margin-bottom: 0;
          text-decoration: none; 

          @include media('<=900px') {
            margin-top: 0;
            font-size: 16px;
            font-weight: 800;
          }
      }
    }
  }

  .quote-container {
    padding: 60px 45px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    //height: 100vh;
    //background-color: #AACA6F;

    @include media('<=900px') {
      padding: 0px 30px 30px;
      height: auto;
    }

    span {
      padding-right: 7px;
      color: #fff;
      font-size: 81px;
      font-family: 'Roboto', sans-serif;
    }

    p {
      font-family: $f-primary;
      font-size: 24px;
      font-weight: 400;
    }
  }

  .community-nav {
    grid-column: 2 / span 2;
    grid-row: 4;


    @include media('<=900px') {
      padding: 0 30px;
      order: 4;
    }

    .slick-next {
      right: 26px !important;
    }
  }

  .community-nav .slick-slide.is-active .thumbnail-container img {
    opacity: 0.5;
  }

  .thumbnail-container {
    // border: 1px solid #AACA6F;
    //margin-right: 45px;
    padding: 10px;
    border-radius: 12px;
    max-width: 180px;


    img {
      border-radius: 12px;
      //border: 1px solid #AACA6F;
      height: 130px;
      width: 180px;
      object-fit: cover;
    }
  }

  .carousel-title {
    grid-column: 2 / span 2;
    grid-row: 3;

    @include media('<=900px') {
      padding: 0 30px;
      order: 3;
    }
  }

  .community-content {
    padding-top: 75px;
    grid-column: 2 / span 2;
    grid-row: 1 / span 2;
    max-width: 900px;
    @extend .p-large;
    font-family: $f-secondary;

    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media('<=900px') {
      padding: 0 30px;
      order: 2;
    }

    .is-style-heading-x-sm {
      font-family: $f-secondary !important;
    }

  }

  .community-nav .slick-slide { cursor: pointer; }

  .community-nav .slick-track {
    margin-left: 0;
  }
}