/* CTA- Testimony */
.cta-testimony {
    
    &.tds {
        @extend .linear-gradient--2;
    }

    &.nm {
        background-image:
        linear-gradient(
          to top, 
          $pale-peach,
          $pale-peach 30%,
            $rose-peach 30%,
            $rose-peach 70%,
          $pale-peach 70%
        );
    }

    &.sierra {
        background-image:
        linear-gradient(
          to top, 
          #C3CDD3,
          #C3CDD3 30%,
          #9EACB5 30%,
          #9EACB5 70%,
         #C3CDD3 70%
        );
    }

    &.dcids {
        background-image:
        linear-gradient(
          to top, 
          $purple,
          $purple 30%,
            $dark-purple 30%,
            $dark-purple 70%,
          $purple 70%
        );
    }

    .container {
        margin: 0 auto;
        padding: 50px 0 0;
        max-width: 1400px;
        display: flex;
        justify-content: center;
        align-items: center;

        @include media('<=1000px') {
            padding: 50px 5% 0;
            flex-direction: column-reverse;
        }
    }

    .col {
        flex: 1 1 50%;

        img {
            vertical-align: bottom;
        }
    }

    .col:first-child {
        align-self: flex-end;
    }

    .col:last-child {
        padding-left: 5%;
        padding-bottom: 50px;

        @include media('<=tablet') {
            text-align: center;
            padding-left: 0;
        }

        p {
            margin: 0 0 25px;
        }
    }

    
}

/* CTA- 2Up */

.cta-2-up {

    &.tds {
        background: linear-gradient(
            to right, 
            $light-blue 50%,
            $dark-blue 50%,
        );
        position: relative;
        overflow: hidden;


        @include media('<=tablet') {
            background: none;
        }

        &::before {
            position: absolute;
            right: -225px;
            top: 50%;
            transform: translateY(-50%);
            content: '';
            background-image: var(--bg);
            background-size: 100% auto;
            background-repeat: no-repeat;
            width: 450px;
            height: 410px;
            opacity: 0.4;

            @include media('<=tablet') {
                background-image: none;
            }
        }
        
        .col:first-child {
            padding-right: 35px;
            display: flex;
            flex-direction: column;

            @include media('<=tablet') {
                background-color: $tissue-bank-blue;
            }

            .btn--small {
                text-align: center;
                align-self: center;
                margin-top: 25px;
            }
        }

        .col:last-child {
            
            color: #fff;

            @include media('<=tablet') {
                background-color: $dark-blue;
            }
        }
    }

    &.sierra,
    &.nm {
        background: linear-gradient(
            to right, 
            $light-blue 50%,
            $dark-blue 50%,
        );
        position: relative;
        overflow: hidden;


        @include media('<=tablet') {
            background: none;
        }

        &::before {
            position: absolute;
            right: -225px;
            top: 50%;
            transform: translateY(-50%);
            content: '';
            background-image: var(--bg);
            background-size: 100% auto;
            background-repeat: no-repeat;
            width: 450px;
            height: 410px;
            opacity: 0.4;

            @include media('<=tablet') {
                background-image: none;
            }
        }

        &.bg-img {

            &::before {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                content: '';
                background-image: var(--bgimg);
                background-size: 100% auto;
                background-repeat: no-repeat;
                opacity: 0.4;
                right: 0;
                width: 50%;
                height: 100%;
            }
        }
        
        .col:first-child {
            padding-right: 5%;
            display: flex;
            flex-direction: column;

            @include media('<=tablet') {
                background-color: $tissue-bank-blue;
            }

            .btn--small {
                text-align: center;
                align-self: center;
                margin-top: 25px;
            }
        }

        .col:last-child {
            color: #fff;
            z-index: 3;

            @include media('<=tablet') {
                background-color: $dark-blue;
            }
        }
    }

    &.dcids {
        background: linear-gradient(
            to right, 
            #C9CFDC 50%,
            $dark-purple 50%,
        );
        position: relative;
        overflow: hidden;


        @include media('<=tablet') {
            background: none;
        }

        &::before {
            position: absolute;
            right: -225px;
            top: 50%;
            transform: translateY(-50%);
            content: '';
            background-image: var(--bg);
            background-size: 100% auto;
            background-repeat: no-repeat;
            width: 450px;
            height: 410px;
            opacity: 0.4;

            @include media('<=tablet') {
                background-image: none;
            }
        }

        &.bg-img {

            &::before {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                content: '';
                background-image: var(--bgimg);
                background-size: 100% auto;
                background-repeat: no-repeat;
                opacity: 0.4;
                right: 0;
                width: 50%;
                height: 100%;
            }
        }
        
        .col:first-child {
            padding-right: 5%;
            display: flex;
            flex-direction: column;

            @include media('<=tablet') {
                background-color: #C9CFDC;
            }

            .btn--small {
                text-align: center;
                align-self: center;
                margin-top: 25px;
            }
        }

        .col:last-child {
            color: #fff;
            z-index: 3;

            @include media('<=tablet') {
                background-color: $dark-purple;
            }
        }
    }

    .container {
        margin: 0 auto;
        padding: 70px 5%;
        max-width: 1400px;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;

        @include media('<=tablet') {
            padding: 0;
            flex-direction: column;
        }
    }


    .col {
        flex: 1 1 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include media('<=tablet') {
            position: relative;
            padding: 50px 5%;
            flex: 100%;
            width: 100%;
            text-align: center;
            align-items: center;
        }

        .btn-container {
            flex: 1 0 auto;
            display: flex;
            align-items: flex-end;

            @include media('<=tablet') {
                width: 100%;
                align-items: center;
                flex-direction: column;
            }

            &.has-text-align-right {
                display: flex;
                justify-content: flex-end;
            }

            &.has-text-align-left {
                display: flex;
                justify-content: flex-start;
            }

            &.has-text-align-center {
                display: flex;
                justify-content: center;
            }

            .btn--small {

                &:not(:last-child):not(:only-child) {
                    margin-right: 33px;

                    @include media('<=tablet') {
                        margin-right: 0;
                    }
                }
            }
        }

        &:last-child {
            padding-left: 5%;

            @include media('<=tablet') {
                //padding-left: 0;
            }

            &::before {

                @include media('<=tablet') {
                    right: -125px;
                    top: 50%;
                    transform: translateY(-50%);
                    content: '';
                    background-image: var(--bg);
                    background-repeat: no-repeat;
                    width: 250px;
                    height: 230px;
                    opacity: 0.4;
                    background-size: 100% auto;
                    position: absolute;
                }
            }
        }
    }
}


/* CTA- BTG */
.cta-btg {
    max-width: 1440px;

    margin: 0 auto;
    display: flex;
    align-items: flex-end;

    @include media('<=1000px') {
        padding: 50px 5% 0;
        display: grid;
        grid-template-rows: repeat(2, 50%);
        grid-template-columns: repeat(2, 1fr);
    }

    .col {
        flex: 1 1 33.3%;


        &:first-child {

            @include media('<=1000px') {
                grid-row: 2;
                grid-column: 1;
            }
        }

        &:nth-child(2) {
            padding: 50px 0;
            align-self: center;
            text-align: center;

            @include media('<=1000px') {
                grid-row: 1;
                grid-column: 1 / span 2;
            }
        }

        &:last-child {
            text-align: right;

            @include media('<=1000px') {
                grid-row: 2;
                grid-column: 2;
            }

            img {
                margin-bottom: 20px;
            }
        }
    }

    img {
        vertical-align: bottom;
    }

}

/* CTA Short */
.cta-short  {
    display: flex;
    justify-content: center;
    align-items: stretch;
    overflow: hidden;

    @include media('<=tablet') {
        flex-direction: column;
    }

    &.tds {
        .col:first-child {
            background-color: $dark-blue;
            color: #fff;
            text-align: center;
        }

        .col:last-child {
            position: relative;
            background-color: #fff;
            color: $dark-blue;

            @include media('<=tablet') {
                padding: 80px 5%;
            }

            &::after {
                position: absolute;
                right: -12%;
                top: 63%;
                transform: translateY(-50%);
                content: '';
                background-image: var(--bg2);
                background-size: auto 100%;
                background-repeat: no-repeat;
                width: 350px;
                height: 300px;
                opacity: 0.4;
                z-index: 1;
            }

            a {
                color: $dark-blue;
            }
        }

        .filterit {
            filter: invert(78%) sepia(26%) saturate(578%) hue-rotate(44deg) brightness(100%) contrast(83%);;
        }
    }

    &.sierra {
        .col:first-child {
            background-color: $dark-blue;
            color: #fff;
            text-align: center;
        }

        .col:last-child {
            position: relative;
            background-color: #fff;
            color: $dark-blue;

            @include media('<=tablet') {
                padding: 80px 5%;
            }

            &::after {
                position: absolute;
                right: -12%;
                top: 63%;
                transform: translateY(-50%);
                content: '';
                background-image: var(--bg2);
                background-size: auto 100%;
                background-repeat: no-repeat;
                width: 350px;
                height: 300px;
                opacity: 0.4;
                z-index: 1;
            }

            a {
                color: $dark-blue;
            }
        }
        .filterit {
            filter: invert(54%) sepia(40%) saturate(5821%) hue-rotate(164deg) brightness(98%) contrast(101%);
        }
    }

    &.nm {
        .col:first-child {
            background-color: $dark-blue;
            color: #fff;
            text-align: center;
        }

        .col:last-child {
            position: relative;
            background-color: #fff;
            color: $dark-blue;

            @include media('<=tablet') {
                padding: 80px 5%;
            }

            &::after {
                position: absolute;
                right: -12%;
                top: 63%;
                transform: translateY(-50%);
                content: '';
                background-image: var(--bg2);
                background-size: auto 100%;
                background-repeat: no-repeat;
                width: 350px;
                height: 300px;
                opacity: 0.4;
                z-index: 1;
            }

            a {
                color: $dark-blue;
            }
        }
        .filterit {
            filter: invert(56%) sepia(88%) saturate(304%) hue-rotate(326deg) brightness(92%) contrast(99%);
        }

        .btn--green {
            border: 2px solid $nm-orange;

            &:hover {
                border: 2px solid $grenadier;
                background-color: $grenadier;
            }
        }
    }

    .col {
        flex: 1 0 50%;

        &:first-child {
            padding: 0 5% 80px 0;
        }

        &:last-child {
            align-self: center;
        }
    }

    .heading {
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    .content {
        padding: 0 5%;
        margin: auto;
        position: relative;
        z-index: 2;
    }
}

/* CTA Podcast */
.cta-podcast {
    margin: 0 auto;
    max-width: 1400px;
    display: flex;
    overflow: hidden;

    @include media('<=tablet') {
        flex-direction: column;
    }

    &.tds {
        position: relative;
        &::after {
            position: absolute;
            right: 0;
            top: 63%;
            transform: translateY(-50%);
            content: '';
            background-image: var(--bg);
            background-size: auto 100%;
            background-repeat: no-repeat;
            width: 400px;
            height: 300px;
            z-index: 1;

            @include media('<=tablet') {
                top: inherit;
                bottom: -152px;     
            }
        }
    }

    .col {
        padding: 50px 5%;
        position: relative;
        z-index: 2;

        @include media('<=tablet') {
            padding: 10px 5%;    
        }

        &:first-child {
            flex: 1 1 30%;
        }

        &:last-child {
            flex: 1 1 70%;

            @include media('<=tablet') {
                text-align: center;
            }
        }
    }

    .images {
        padding: 45px 35px;
        background-color: $dark-blue;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .podcast-list {
        padding-left: 0;
        display: flex;
        flex-flow: row wrap;
        align-items: center;

        @include media('<=tablet') {
            flex-wrap: nowrap;
            flex-direction: column;
        }

        li {
            list-style-type: none;
            flex: 0 0 33.3%;

            @include media('<=tablet') {
                flex: 0 0 100%;
            }
        }
    }
}


.multi-cta {
    background-image: linear-gradient(0deg, rgba(52,43,73,0.7) 38%, rgba(0,0,0,0.7) 64%) ,var(--bg-image);
    background-repeat: no-repeat;
    background-size: cover;

    &.tds,
    &.sierra,
    &.nm {
        background-image: linear-gradient(0deg, rgba(0, 9, 58, 0.43) 0%, rgba(0, 9, 58, 0.43) 100%) ,var(--bg-image);
    }

    &.position--top {
        background-position-y: top;
    }

    &.position--bottom {
        background-position-y: bottom;
    }

    &.position--center-y {
        background-position-y: center;
    }

    &.position--left {
        background-position-x: left;
    }

    &.position--center {
        background-position-x: center;
    }
    &.position--right {
        background-position-x: right;
    }

    .multi-cta-container {
        margin: 0 auto;
        padding: 70px 5%;
        max-width: 1400px;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;

        &.flex-column {
            flex-direction: column;
        }

        &.flex-row {
            flex-direction: row;

            .buttons {

                a {

                    &:not(:last-child) {
                        margin-right: 0;
                    }
                }
            }
            
            @include media('<=tablet') {
                flex-direction: column;
            }
        }
    }

    .col {
        width: 100%;

        .buttons {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;



            a {
                width: 360px;

                &:not(:last-child) {
                    margin-right: 30px;

                    @include media('<=tablet') {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

.title-button {
    margin-top: 75px;

    @include media('<=tablet') {
        margin-top: 50px;
    }

    .title-button-container {
        margin: 0 auto;
        max-width: 1200px;
        padding: 0 5%;

        .col {

            &:first-child {
                @include media('<=tablet') {
                    margin-bottom: 50px;
                }
            }
        }

        .btn-wrapper {
            position: relative;
            background-image: var(--bg-image);
            background-repeat: no-repeat;
            background-size: 100% auto;

            position: relative;
            display: flex;
            justify-content: center;

            img {
                vertical-align: bottom;

                @include media('<=tablet') {
                    height: 150px;
                }
            }

            a {
                position: absolute;
                align-self: end;
                margin-bottom: 75px;

                @include media('<=tablet') {
                    margin-bottom: 50px;
                }
            }
        }
    }
}

.cta-banner {
    
    .cta-banner-wrapper {
        display: flex;
        align-items: center;

        @include media('<=tablet') {
            flex-direction: column;
            align-items: flex-start;
        }


    .flex-group {
        display: flex;
        align-items: center;
        margin-left: 25px;

        a {
            margin-left: 10px;
        }
    }

        p {
            margin: 0;
        }

        a {
            text-decoration: none;
            color: white;
            margin-left: 20px;

            @include media('<=tablet') {
                margin-left: 0;
            }
        }
    }
    
}