.search-form{
  
  padding-bottom: 25px;
  [type=search]{
    line-height: 1.5em;
    padding: 5px 20px;
    text-align: left;
  }
}




.searchresults{
  max-width: 1200px;
  margin:0 auto;
  padding:0 3%;
    
  .searchwrap{
    margin-bottom: 40px;
      margin-top: 20px;
  
  }
  
  .result{
    margin:20px 0 40px 0;
    a{
      text-decoration: none;
      &:hover{
        text-decoration: underline;
      }
    }
  }
  .pagination{
    font-size: 1.2em;
    border-top: 1px solid;
    padding: 25px 0;
     a {
      margin: 0 8px;
    }
  }
}