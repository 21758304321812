.donor-memorial-cards {
    background-color: $dark-blue;
    position: relative;
    overflow: hidden;

    &.dcids {
        background-color: $dark-purple;

        .donor-wrapper .memorial-cards .card {
            background-color: #5A5E79;

            p {
                color: #fff;
            }
        }
    }

    &::before {
        position: absolute;
        content: '';
        bottom: -40%;
        right: -200px;
        background-image: var(--bg-image);
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;
        width: 500px;
        height: 100%;
        opacity: 0.3;
    }

    .innercontainer--1440 {
        padding: 80px 5%;
    }

    .donor-wrapper {
        display: flex;
        align-items: center;
        position: relative;
            
        @include media('<=1240px') {
            flex-direction: column;
        }

        .content {
            width: calc(50% - 40px);
            margin-right: 40px;

                        
            @include media('<=1240px') {
                width: 100%;
                margin-right: 0;
                margin-bottom: 50px;
            }
        }

        .memorial-cards {
            width: 50%;
            display: flex;

            @include media('<=1240px') {
                width: 100%;
            }   

            @include media('<=tablet') {
                flex-direction: column;
            }

            .card {
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                text-align: center;
                background-color: #79AAC0;
                padding: 38px;
                border-radius: 9px;

                @include media('<=tablet') {
                    width: 100%;
                }

                &:not(:last-child) {
                    margin-right: 30px;

                    @include media('<=tablet') {
                        margin-right: 30px;
                        margin-bottom: 25px;
                    }
                }

                .img-wrapper {
                    margin-bottom: 40px;
                    position: relative;

                    img {
                        border-radius: 9px;
                        width: 100%;
                        height: 230px;
                        object-fit: cover;
                    }

                    .donor-badges {
                        width: 100%;
                        position: absolute;
                        left: 50%;
                        transform: translate(-50%, -32.5px);
                        display: flex;
                        justify-content: center;

                        img {
                            margin: 2.5px;
                            width: 65px;
                            height: 65px;
                        }
                    }
                }

                p {
                    margin-top: 0;
                    color: $dark-blue;
                    font-weight: 600;
                }

            }
        }
    }

    .btn--is-style-arrow {
        color: white;
    }
}