.text-block{
  
  .innercontainer{
    max-width: 1200px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .separator{
    margin-top:50px;
    hr{
      border: 1px solid #d4d4d4 !important;
      margin:9px 0;
    }
  }

}

.text-wrap {

  &::after {
    content: "";
    clear: both;
    display: table;
  }

  .wp-block-image {
    margin-right: 60px;

    .alignleft {
      padding: 12px;
      border-radius: 16px 16px 0 0;
      background-color: $purple;
    }

    figcaption {
      margin: 0 !important;
      text-align: center;
      background-color: $purple;
      padding-bottom: 12px;
      border-radius: 0 0 16px 16px;
      color: white !important;
    }
  }
}