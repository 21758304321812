.stats {
    &.tds {
        background-color: $tn-green;
    }

    &.sierra {
        background-color: $tn-green;
    }


    &.nm {
        background-color: $nm-orange;

        h4 {
            color: $dark-blue;
        }
    }

    &.dcids {
        background-color: $dark-purple;

        .stats-text,
        h2 {
            color: #fff;
        }
    }

    &-container {
        padding: 55px 5%;
        margin: 0 auto;
        max-width: 1400px;

        h2 {
            color: #222 !important;
        }
    }

    .p-large--bold {
        text-align: center;
        color: #666;

        @include media('<=tablet') {
            font-size: 1.125rem;
            font-weight: 400;
            line-height: 1.5;
            color: $dark-blue;
        }
    }

    .stats-row {
        width: 100%;
        display: flex;

        @include media('<=tablet') {
            flex-direction: column;
        }

        .slick-pause-icon {
            display: none;
        }

        .slick-dots {
            display: none;
        }
    }

    .col {
        text-align: center;
        flex: 1 1 25%;

        @include media('<=tablet') {
            display: flex !important;
            justify-content: center;
            align-items: flex-end;
        }
    }
    .stats-wrap{
      font-family: 'Raleway';
      color: #fff;
      font-weight: 900;
      letter-spacing: -11px;
      font-size: 5rem;
      text-shadow: 1px 1px #222;
    }
    .stats-num { //js generated element 
      @include media('<=tablet') {
          display: inline-block;
          align-self: flex-start;
          transform: rotate(-20deg);
          text-align: left;
          letter-spacing: -5px;
          z-index: 1;
      }
    }

    .stats-content {

        @include media('<=tablet') {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
    }

    .stats-text {
        color: $dark-blue;
        @extend .p-large--bold;

        @include media('<=tablet') {
            margin-bottom: 0;
            position: relative;
            z-index: 2;
        }
    }
}