.stories{ //block

  &.tds {
    .stories-for .story-slide .story-content {
      background-color: $light-blue;
    }
  }

  &.sierra {
    .stories-for .story-slide .story-content {
      background-color: #AACC6F;
    }
  }

  &.nm {
    .stories-for .story-slide .story-content {
      background-color: $light-blue;
    }

    .stories-nav .slide .imgwrap {
      border: 1px solid $nm-orange;
    }

    .stories-nav .slide .imgwrap img {
      border: 1px solid $nm-orange;
    }
  }
  .postheader{
    //background: linear-gradient(to right, rgba(255,62,65,1) 0%,rgba(255,62,65,1) 14%,rgba(255,62,65,1) 14%,rgba(163,32,53,1) 14%,rgba(163,32,53,1) 84%,rgba(255,152,150,1) 84%,rgba(255,152,150,1) 100%);
   // background: linear-gradient(to right, #349425 0%,#349425 14%,#349425 14%,#2E473A 14%, #2E473A 84%,#3CAD2B 84%,#3CAD2B 100%);
  }
  .profile-wrap{
    display: none;
  }
  .profiles{
    display: block;//override flex
  }


  .stories-nav //slick navigation
  {
    padding: 0 40px;
    margin-top: 35px;
    margin-bottom: 45px;
    
    .slide
    {
      padding:0 20%;
      .imgwrap {
        display: block;
        padding:10px;
        transition:opacity .2s;
        opacity: .5;
        border-radius: 12px;
        border: 1px solid green;
        img
        {   
          border-radius: 12px;
          border: 1px solid green;
          height: 130px;
          width: 100%;
          object-fit: cover;
        }
      }
    }
    .current .slide a
    {
      opacity: 1;
      
    }

    .slick-arrow{
      position: absolute;
      z-index: 5;
      top: 50%;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      color:transparent;
      border: none;
      background-position: center;
      width: 40px;
      overflow: hidden;
    }
    .slick-arrow:hover{
      cursor: pointer;
    }
    .slick-next{
      background-image: url('../../assets/images/RightArrow.svg');
      right: 0;
    }
    .slick-prev{
      background-image: url('../../assets/images/LeftArrow.svg');
      left: 0;
    }


    .slick-prev .slick-prev-icon:before,
    .slick-next .slick-next-icon:before,
    .slick-prev:before,
    .slick-next:before{
      content: '';//removing default arrow bd
    }   


    .slick-prev .slick-prev-icon {
      visibility: hidden; // removing small arrow
    } 
  }//end stories-nav

  .slick-slide.slick-current.slick-active.slick-center .slide .imgwrap{
    opacity: 1;
  }
  .stories-for{ //slick content
    .story-slide{
      display: flex !important;
      flex-flow: row nowrap;


      @include media('<=1200px') {
        flex-direction: column-reverse;
        align-items: flex-end;
      }

      .story-video{
        width: 60%;
        padding: 0 2%;
        border-radius: 12px;

        @include media('<=1200px') {
          width: 100%;
          padding: 35px 5% 0;
        }

        .video-wrapper {
          max-width: 1020px;
          margin: 0 auto;
          padding-bottom: 54.25%;
          padding-top: 25px;
          position: relative;

          iframe {
            max-width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 12px;
          }
        }

      }
      .story-content{
        width: 40%;
        margin-right: 0;
        border-radius: 12px 0 0 12px;
        padding: 20px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        //background-color: $light-blue;

        @include media('<=1200px') {
          width: calc(100% - 30px);
          margin-left: 30px;
        }

        .container {
          max-width: 500px;
        }
      }
    }
  }//end .slick-for
  


}//end stories


// Theme styles for leadership profile
.leadership {

  &.tds {
    .card-bio .bio-img {
      background: $tn-green;
    }
  }

  &.sierra {
    .card-bio .bio-img {
      background: #C3CDD3;
    }

    .leadershipnav .slide a {
      border: 1px solid #9EACB5;

      .bg {
        border: 1px solid #9EACB5;
      }
    }
  }

  &.nm {
    .card-bio .bio-img {
      background: $grenadier;
    }

    .leadershipnav .slide a {
      border: 1px solid $rose-peach;

      .bg {
        border: 1px solid $rose-peach;
      }
    }
  }
}



