/*
*  COLORS
*/

// TDS
$dark-blue: #07314D;
$teal: #52C7C0;
$beau-blue: #C3D1DD;
$tissue-bank-blue: #A8CEE5;
$light-blue: #E0E8EE;
$brown-gray: #666666;
$tn-green: #9FC86C;
$dci-gray: #AFB6B5;
$light-gray: #B8B0AC;
$purple-navy: #4A5077;
$oxley-green: #80A28B;
$birth-tissue-pink: #EFD9E6;

// Sierra 
$sierra-card-blue: #1C6897;
$sds-bright-blue : #00A6DF;

// NM
$nm-orange : #E46036;
$grenadier: #D44214;
$pale-peach : #EA8667;
$rose-peach: #E17A5A;
$teal-blue: #52C7BF;


// DCIDS
$dark-purple : #342B49;
$purple : #4A5077;
$peach : #D1998A;
$lavender: #C9CFDC;
$pale-blue: #E9F2F6;

$birth-tissue-purple : #E4BEEA;

//DCIDS CAREERS
  $career-blue: #97C0DC;
  $career-faded-gray: #98A4AE;
  $career-pale-blue: #F2F5F8; // application process background
