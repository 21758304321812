.events {
  max-width: 1400px;
  margin: 50px auto;
  padding: 0 5%;

  .heading-l {
      margin-bottom: 25px;
  }

  .events-wrapper {
      width: 100%;
      max-width: 1240px;
      margin:0 auto;
      display: flex;

      @include media('<=1000px'){
          flex-direction: column;
      }
  }

  .event-highlight {
    // width:75%;
    display: flex;
    align-items: center;
    flex: 1;

    @include media('<=1000px'){
        width:100%;
    }
    @include media('<=768px'){
      flex-direction: column;
      width: 100%;
      padding: 0;
      display: flex;
      justify-content: center;
    }

      // div {
      //     flex: 0 1 auto;
      //     &:first-child {
      //         margin-right: 60px;

      //         @include media('<=1000px'){
      //             margin-right: 0px;
      //         }
      //     }
      // }

      .img-wrapper {
        min-width: 43%;
        max-width: 43%;
        margin-right: 4.1%;
        align-self: flex-start;

        @include media ('<=768px'){
          width: 100%;
          padding: 0 3%;
          display: flex;
          justify-content: center;
          margin: 0 auto;
        }
    }

    .event-content {
      // width: 54%;
      //margin-right: 3%;
      //padding-right: 3%;

      height: 100%;
      padding-top: 30px;
      padding-bottom: 30px;
      border-top: 1px solid #AACC6F;
      border-bottom: 1px solid #AACC6F;
      flex: 1;
      @include media('<=768px'){
        margin: 0;
        padding:30px 0;

        h4.heading-l{
          text-align: left !important;
        }
        
      }
    
      @include media('<=1000px'){
          border-top: none;
          width: 100%;
      }
    }
  }

  .events-alt {
    width: 25%;
    margin-left: 3%;
    border-top: 1px solid #AACC6F;
    border-bottom: 1px solid #AACC6F;

    @include media('<=1000px'){
        border-top: none;
        width: 100%;
    }


    .event-content {
      // @include media('<=1000px'){
      //   width: 50%;
      // }
      // @include media('<=560px'){
      //   width: 100%;
      // }
        padding: 20px 0;
        &:first-child {
            border-bottom: 1px solid #AACC6F;
        }
        &:last-child {
          border-bottom-color: transparent;
        }
        @include media('<=1000px'){
          h4.heading-l{
            text-align: left !important;
            margin-bottom: 5px;
          }
          .btn{
            margin-top:5px;
          }
        }
    }
  }

  // styles for when there is more than one event

  // .events-wrapper--flex {
  //     display: flex;

  //     @include media('<=1000px'){
  //         flex-direction: column;
  //     }

  //     .event-highlight {
  //         margin-right: 4.5%;
  //         flex: 0 1 auto; 

  //         @include media('<=1000px'){
  //             margin-right: 0;
  //             width: 100%;
  //         }
          
  //         .img-wrapper {
  //             width: 31.4%;
  //             margin-right: 4.5%;

  //             @include media('<=1000px'){
  //                 margin-right: 0;
  //                 width: 100%;
  //             }
  //         }

  //         .event-content {
  //             width: 35%;

  //             @include media('<=1000px'){
  //                 margin-right: 0;
  //                 width: 100%;
  //             }
  //         }
  //     }

  //     .events-alt {
  //         width: 24.5%;
  //         border-top: 1px solid #AACC6F;
  //         border-bottom: 1px solid #AACC6F;

  //         @include media('<=1000px'){
  //             border-top: none;
  //             width: 100%;
  //         }

  //         .event-content {
  //             padding: 20px 0;

  //             &:first-child {
  //                 border-bottom: 1px solid #AACC6F;
  //             }
  //         }
  //     }
  // }



.date {
  color: #1C6897;
  text-transform: uppercase;
  font-size: 14px;
  font-family: $f-primary;
  font-weight: 400;   
  line-height: 1.2;
}

.read-more {
  color: #1C6897;
  position: relative;

  &::after {
    position: absolute;
    content: '';
    right: -101px;
    top: 50%;
    transform: translate(0%, -29%);
    background-image: url("./wp-content/themes/donor services/assets/images/read-more-arrow.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 100%;
  }
}

}
















// single events

.events-single-heading {
  max-width: 1200px;
  margin: 50px auto;
  padding: 0;
  color:$brown-gray;
  line-height: 1.5em;
  @include media('<=1200px'){
     padding: 0 3%;
  }
  .events-wrapper {
      width: 100%;
      max-width: 1240px;
      margin:0 auto;
      display: flex;

      @include media('<=1000px'){
          flex-direction: column;
      }
  }

  .event-highlight {
    // width:75%;
    display: flex;
    align-items: center;
    flex: 1;

    @include media('<=1000px'){
        width:100%;
    }
    @include media('<=768px'){
      flex-direction: column;
      width: 100%;
      padding: 0;
      display: flex;
      justify-content: center;
    }

      // div {
      //     flex: 0 1 auto;
      //     &:first-child {
      //         margin-right: 60px;

      //         @include media('<=1000px'){
      //             margin-right: 0px;
      //         }
      //     }
      // }

      .img-wrapper {
        min-width: 43%;
        max-width: 43%;
        padding-right:4.1%;
        margin-right: 4.1%;
        align-self: flex-start;
        border-right: 2px solid #AACC6F;
        @include media ('<=768px'){
          width: 100%;
          max-width: 100%;
          padding: 0 3%;
          display: flex;
          justify-content: center;
          margin: 0 auto;
          border-right:none;
        }
    }

    .event-content {
      color:$brown-gray;
      line-height: 1.5em;
      height: 100%;
      padding-top: 30px;
      padding-bottom: 30px;
      flex: 1;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      
      @include media('<=768px'){
        margin: 0;
        padding:30px 0;
        text-align: center;
        h4.heading-l{
          text-align: left !important;
        }

        .date{
          order:-1;
        }
        
      }
    
      @include media('<=1000px'){
          border-top: none;
          width: 100%;
      }

      .location{
        color: $dark-blue;
      }
    }
  }


  .social-share {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #000;
    @include media('<=1000px') {
        flex-direction: column;
    }
    @include media('<=768px') {
      flex-direction: row;
      font-size: 16px;
      padding: 0 2%;
      border-bottom: 3px solid #AACC6F;
      margin-top: 16px;
    }
    
    p {
        font-family: $f-secondary;
        padding-right: 50px;

        @include media('<=1000px') {
            padding-right: 0;
        }
        @include media('<=768px') {
            font-size:16px;
        }
    }

    .social-share-icons {
      @include media ('<=768px'){
        margin-left: auto;
      }
        a {
            text-decoration: none;

            &:not(:last-child) {
                margin-right: 32px;
                @include media ('<=768px'){
                  margin-right: 16px;
                }
            }

            svg {
                display: inline-block;
                width: auto;
                height: 29px;
                @include media ('<=768px'){
                  height:19px;
                }
            }
        }
    }
  }


}


.events-single-content{
  margin: 0 auto;
  padding: 0;
  max-width: 1200px;
  color:$brown-gray;
  line-height: 1.5em;
  @include media('<=1200px'){
    padding: 0 3%;
 }
}

.boilerplate{
  max-width: 1200px;
  margin: 0 auto;
  @include media('<=1200px'){
    padding: 0 3%;
 }
}