.footer-form {
    padding: 46px 100px;
    display: flex;
    justify-content: center;

    #mc_embed_signup input {
        border: 2px solid #ABB0B2 !important;
    }

    &.tds {
        //background-color: $tn-green;

        //div.ctct-form-embed div.ctct-form-defaults {
            //background-color: $tn-green;
       // }

       // .form-wrapper {
           // display: flex;
            //flex-direction: column;
       // }
    }

    &.sierra,
    &.nm,
    &.tds{
        background-color: #00A6DF;

        div.ctct-form-embed div.ctct-form-defaults,
        #mc_embed_signup {
            background-color: #00A6DF !important;
            background: #00A6DF !important;

            max-width: 1072px !important;
            grid-column: 1 / span 3;
            grid-row: 1;

            .mc-field-group {
                padding-bottom: 0 !important;
            }
        }

        //mail-chimp sign-up form
        #mc_embed_signup {
            //width: 70% !important;
            width: 100% !important;

            form {
                margin: 0 !important;
            }
        }

        #mc_embed_signup_scroll {
            width: 100%;
            display: grid;
            //column-gap: 30px;
            grid-template-columns: repeat(3, 1fr);
            align-items: end;

            @include media('<=tablet') {
                grid-template-columns: auto;
                justify-items: center;
            }
        }

        #mc_embed_signup .helper_text {
            display: none !important;
        }

        #mc_embed_signup .indicates-required {
            display: none;
        }

        #mce-success-response[style*="display: block"],
        #mce-success-response[style*="display: block"] {
        display: flex !important;
        align-items: center;
        }

        #mc_embed_signup #mce-success-response {
            background-color: #00A6DF !important;
            margin-left: 0 !important;
            height: 100px !important;
            width: inherit !important;
            color: #07314D !important;

            @include media('<=tablet') {
                height: 220px !important;
            }
        }

        #mc_embed_signup div#mce-responses {
            position: absolute;
            left: 0;
            width: 100% !important;
            margin: 0 !important;
            padding: 0 !important;
        }

        #mc_embed_signup .mc-field-group label {
            color: #07314D !important;
            font-weight: 700 !important;
            font-size: 16px !important;
        }

        #mc_embed_signup .mc-field-group input {
            border-radius: 6px;
        }

        #mc-embedded-subscribe {
            $min_font: 1rem;
            $max_font: 1.25rem;
            
            @include fluid-type($min_width, $max_width, $min_font, $max_font);
            font-family: $f-secondary;
            font-weight: 400;
            color: $dark-blue;
            text-decoration: none;
            display: inline-block;
            margin-top: 25px;
            margin-bottom: 0 !important;
            padding: 0.5rem 1.5rem;
            border-radius: 100rem;
            border: solid 3px transparent !important;
            background-image: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0)),linear-gradient(89.7deg, #6AC8AB 3.12%, #1E96FC 99.7%) !important;
            background-origin: border-box !important;
            background-clip: content-box, border-box !important;
            box-shadow: 2px 1000px 1px #fff inset !important;
            height: auto;

            &:hover {
                box-shadow: none !important;
                color: white;
            }
        }
    }

    &.nm {
        background-color: $grenadier;

        .heading-x-sm {
            color: #fff;
        }

        div.ctct-form-embed div.ctct-form-defaults,
        #mc_embed_signup {
            background-color: $grenadier !important;
            background: $grenadier !important;
        }

        #mc_embed_signup .mc-field-group label {
            color: #fff !important;
        }

        #mc_embed_signup #mce-success-response {
            background-color: $grenadier !important;
            margin-left: 0 !important;
            height: 100px !important;
            width: 100% !important;
            color: white !important;

            @include media('<=tablet') {
                height: 220px !important;
            }
        }

        #mc_embed_signup .asterisk {
            color: #fff !important;
        }

        #mc-embedded-subscribe {
            $min_font: 1rem;
            $max_font: 1.25rem;
            
            @include fluid-type($min_width, $max_width, $min_font, $max_font);
            font-family: $f-secondary;
            font-weight: 400;
            color: $dark-blue;
            text-decoration: none;
            display: inline-block;
            margin-top: 25px;
            padding: 0.5rem 1.5rem;
            border-radius: 100rem;
            box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
            background-image: linear-gradient(0deg, rgba(82,199,192,1) 8%, rgba(167,203,118,1) 23%, rgba(106,200,171,1) 74%, rgba(82,199,191,1) 89%);
            border: solid 2px #AACA6F;
            box-shadow: 2px 1000px 1px #fff inset;
            height: auto;

            &:hover {
                box-shadow: none;
                color: white;
                background-color: #AACA6F;
            }
        }

        .form-disclaimer {
            p {
               color: white;

               a {
                color: white;
               }
            }
         }
    }

    &.tds {
        background-color: $tn-green;

        #mc_embed_signup #mce-success-response {
            background-color: #9FC86C !important;
            margin-left: 0 !important;
            height: 100px !important;
            width: 1072px !important;
            color: #07314D !important;

            @include media('<=tablet') {
                height: 220px !important;
            }
        }

        div.ctct-form-embed div.ctct-form-defaults,
        #mc_embed_signup {
            background-color: $tn-green !important;
            background: $tn-green !important;

            .refferal_badge {
                display: none !important;
            }
        }

        #mc-embedded-subscribe {
            $min_font: 1rem;
            $max_font: 1.25rem;
            
            @include fluid-type($min_width, $max_width, $min_font, $max_font);
            font-family: $f-secondary;
            font-weight: 400;
            color: $dark-blue;
            text-decoration: none;
            display: inline-block;
            margin-top: 25px;
            padding: 0.5rem 1.5rem;
            border-radius: 100rem;
            box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
            background-image: linear-gradient(0deg, rgba(82,199,192,1) 8%, rgba(167,203,118,1) 23%, rgba(106,200,171,1) 74%, rgba(82,199,191,1) 89%);
            border: solid 2px #AACA6F;
            box-shadow: 2px 1000px 1px #fff inset;
            height: auto;

            &:hover {
                box-shadow: none;
                color: white;
                background-color: #AACA6F;
            }
        }
    }

    &.dcids {
        display: none;
    }


    .heading-x-sm {
        color: $dark-blue;
        margin-top: 50px;
        margin-bottom: 0 ;

        @include media('<=1550px') {
            width: 50%;
        }
        @include media('<=1370px') {
            width: 100%;
        }

        p {
            margin: 0;
        }
    }

    //reset some of the form styles from constant contact 
  div.ctct-inline-form{
    width: 100%;
   // width: 70% !important;
    //max-width: 1072px !important;
  }

  div.ctct-form-embed div.ctct-form-defaults {
    padding: 0px 20px;
    padding-left: 10px !important;
  }
  div.ctct-form-embed form.ctct-form-custom input.ctct-form-element{
    border-radius: 6px;
  }
  div.ctct-form-embed form.ctct-form-custom{
    row-gap: 0px;
    column-gap: 30px;
  }
  div.ctct-form-embed form.ctct-form-custom button.ctct-form-button{
    font-weight: 400 !important;
    color: #07314D !important;
    text-decoration: none !important;
    display: inline-block !important;
    padding: 0.5rem 0.75rem !important;
    border-radius: 100rem;
    white-space: nowrap;
    border: solid 3px transparent !important;
    background-image: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0)),linear-gradient(89.7deg, #6AC8AB 3.12%, #1E96FC 99.7%) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
    box-shadow: 2px 1000px 1px #fff inset !important;
    font-size: 1.125rem !important;
    cursor: pointer;
  }

  div.ctct-form-embed div.ctct-form-defaults p.ctct-gdpr-text {
    margin-bottom: 0 !important;
  }

  div.ctct-form-embed div.ctct-form-defaults .ctct-form-footer {
    padding: 0 !important;
    margin: 0 !important;
  }

  @media all and (max-width:1370px){
    flex-direction: column;
    padding: 46px 50px;
    
    & div.ctct-inline-form{
      width: 100% !important;
    }
    & .heading-x-sm{
      margin-left: 20px;
    }
  }

  .form-wrapper {
    max-width: 1072px;
    //width: 70%;
    margin: 10px 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-row-gap: 10px;

    .form-disclaimer {
        grid-column: 1 / span 2;
        grid-row: 2;

        @include media('<=tablet') {
            grid-column: 1 / span 3;
            text-align: center;
        }

        p {
           font-size: 11px;
        }
     }
  }


}



.footer {
    display: flex;
    align-items: center;
    background-color: $dark-blue;
    color: white;
    overflow: hidden;

    @include media('<=1100px') {
        flex-direction: column-reverse;
    }

    // .footer-img
    &-img {
        margin-right: 5%;
        width: 10%;
        flex: 1 0 10%;
        position: relative;

        @include media('<=1100px') {
            display: flex;
            justify-content: center;
            margin-right: 0;
            overflow: hidden;
            width: 100%;
            transform: inherit;
            flex: initial;
            max-height: 30vh;
        }

        img {
            position: relative;
            object-fit: cover;
            max-width: 170px;


            @include media('<=1100px'){
                transform: rotate(-90deg);
                max-width: 100%;
                height: fit-content;
                left: initial;
            }
        }

    }

    // .footer-content
    &-content {
        padding: 60px 0;
        display: flex;
        justify-content: space-between;
        width: 100%;

        @extend .p-small;

        @include media('<=1100px') {
            width: auto;
            padding-bottom: 0;
            flex-direction: column-reverse;
            text-align: center;
        }

        a {
            color: white;   
        }

        ul {
            padding-left: 0;
        }
    }

    .col {
        margin-right: 5%;

        @include media('<=1100px') {
            margin-right: 0;
            margin-bottom: 10%;
        }

        .link-wrapper {
            margin-top: 20px;
            display: grid;
            grid-template-columns: repeat( auto-fill, minmax(75px, auto) );
            grid-gap: 15px;
            max-width: 300px;

            li {
                list-style-type: none;

                &.full-width {
                    grid-column: 1 / span 3;
                }

                a {
                    text-decoration: none;
                }
            }
        }
    }

    .col:first-child {
        flex: 1 0 30%;
        display: flex;
        flex-direction: column;
        align-self: center;

        @include media('<=1100px') {
            margin: 0 auto;
            max-width: 400px;
            width: 100%;
            align-items: center;
        }

        .footer-logo {
            flex: 0 1 70%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            align-content: space-evenly;

            img {
                max-width: 200px;
            }
        }
    }

    .col:nth-child(2),
    .col:nth-child(3),
    .col:last-child
     {
        flex: 1 0 20%;

        @include media('<=1100px') {
            width: 100%;
            display: flex;
            justify-content: center;
        }

        dl > *,
        ul > * {
            margin-bottom: 10px;
            list-style-type: none;
        }
    }

    .social-links {
        margin-top: 15px;
        display: flex;
        flex: 0 1 30%;
        align-items: flex-end;

        @include media('<=1100px') {
            display: none;
        }

        a {

            &:not(:last-child) {
                margin-right: 20px;
            }

            img {
                height: 25px;
            }
        }
    }

}

// DCIDS Theme styles: Footer
/*
#dcids {

    .dcids-footer {
        padding: 0 5% 75px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(0deg, rgba(52,43,73,0.7) 38%, rgba(0,0,0,0.7) 64%),var(--bg-image);
        display: flex;
        flex-direction: column;

        @include media('<=1100px') {
            padding-bottom: 50px;
        }

        .footer-container {
            max-width: 1200px;
            margin: 0 auto;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(4, auto);
            grid-gap: 15px;
            justify-items: center;
            width: 100%;

            @include media('<=1100px') {
                grid-template-columns: 1fr;
                //grid-gap: 25px;
            }

            .footer-img {
                margin-right: 0;
                width: inherit;
                grid-row: 1;
                grid-column: 1 / span 3;

                @include media('<=1100px') {
                    grid-column: 1;
                }

                img {
                    max-width: 280px;
                    transform: inherit;

                    @include media('<=tablet') {
                        height: auto;
                    }
                }
            }

            .footer-logo-container {
                grid-row: 2;
                grid-column: 1 / span 3;

                @include media('<=1100px') {
                    grid-column: 1;
                }
            }
        }

        .footer-wrapper {
            display: flex;
            width: 100%;

            grid-row: 3;
            grid-column: 1 / span 3;

            @include media('<=1100px') {
                flex-direction: column;
                grid-column: 1;
            }

            .col {
                display: block;
                margin-right: 0;
                width: 100%;
                flex: initial;
                color: white;

                &:last-child ul {

                    @include media('<=1100px') {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                    }

                    li {
                        @include media('<=1100px') {
                            margin: 0 15px;
                        }
                    }
                  }

                @include media('<=1100px') {
                    margin-bottom: 25px;
                }


            }
        }


        .footer-buttons {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: center;
            width: 100%;

            grid-row: 4;
            grid-column: 1 / span 3;

            @include media('<=1100px') {
                flex-direction: column;
                grid-column: 1;
            }

            .button-wrapper {
                margin: 0 12.5px;
                width: calc(100% / 3 - 25px);

                @include media('<=1100px') {
                    margin: 0;
                    width: 100%;
                }
            }

            .btn.btn--transparent {
                width: 100%;
                font-size: 1.125rem;
                line-height: 1.2;
            }
        }

        a {
            color: #fff;
        }

        ul {
            padding-left: 0;
            list-style-type: none;
            text-align: center;
        }
    }
}

*/

#dcids .footer {
    background: $dark-purple;
}
