.posts-filter {
    margin: 70px 0;
    display: flex;
    align-items: center;
    padding: 0;

    @include media('<=tablet') {
        flex-direction: column;
    }

    div.first {
        margin-right: 100px;

        @include media('<=tablet') {
            margin-right: 0;
            margin-bottom: 30px;
        }
    }

    ul.second {
        display: flex;
        align-items: center;

        @include media('<=tablet') {
            display: flex;
        }
    }

    li {
        list-style-type: none;

        &:not(:last-child) {
            margin-right: 100px;

            @include media('<=tablet') {
                margin-right: 50px;
            }
        }
    }

    a {
        text-decoration: none;
        @extend .p-large--bold;
        color: #00477F;
        text-transform: uppercase;


        &.active {
            font-size: 40px;
            font-weight: 600; 
            margin-bottom: 15px;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                width: 100%;
                right: 0;
                bottom: -15px;
                border-bottom: 3px solid $tn-green;
            }
        }
    }
}

.paginate {
    margin: 30px 0;
    text-align: center;

    .pagination {
        padding-left: 0;
    }

    .page-numbers:not(:last-child) {
        margin-right: 20px;
    }
}