.registration-goal {

    &.tds {
        .registration-progress {
            background-color: #6BA137;
        }
    }
    &.sierra {
        .registration-progress {
            background-color: #00A6DF;
        }
    }
    &.nm {
        .registration-progress {
            background-color: $teal-blue;
        }
        
        .social-share-icons svg path {
            fill: #E46036;
        }
    }

    .registration-container {
        display: flex;
        justify-content: space-between;
    }

    .registration-bar {
        background-color: #ddd;
        width: 100%;
        height: 50px;
        overflow: hidden;
    }

    .registration-progress {
        width: calc(var(--signups) * 100% / var(--goal));
        //background-color: #6BA137;
        height: inherit;
    }

    .social-share {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .social-share-icons {
        margin-left: 35px;

        a {
            text-decoration: none;

            &:not(:last-child) {
                margin-right: 25px;
            }
        }

        svg {
            height: 35px;
        }
    }
}