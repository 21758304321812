// Constant Contact Form in Footer

div.ctct-form-embed form.ctct-form-custom {
    display: grid;
    grid-template-columns: 1fr 1fr 15%;
   // grid-template-rows: repeat(2, 1fr);
    grid-gap: 25px;
    align-items: end;

    @include media('<=tablet') {
        grid-template-columns: 100%;
        grid-template-rows: repeat(4, 1fr);
    }

    // form heading
    /*
    .ctct-form-embed.form_0 .ctct-form-defaults .ctct-form-header {
        grid-row: 1;
        justify-self: center;
    }

    #ctct_form_0 .ctct-form-header {
        font-family: $f-primary !important;
        font-weight: 600 !important;
    }

    div.ctct-form-embed div.ctct-form-defaults h2.ctct-form-header {
        font-family: $f-primary !important;
        font-weight: 600 !important;
    }*/

    // input containers
    div.ctct-form-embed form.ctct-form-custom div.ctct-form-field,
    #email_address_field_0,
    #custom_field_string_full_name_field_0 {
        margin: 0 !important;
    }

    // email field
    #email_address_field_0 {
        grid-column: 2;
        grid-row: 1;

        @include media('<=tablet') {
            grid-column: 1;
            grid-row: 2;
        }

    }

    // full name field
    #custom_field_string_full_name_field_0 {
        grid-column: 1;
        grid-row: 1;

        @include media('<=tablet') {
            grid-column: 1;
            grid-row: 1;
        }
    }

    // input labels 
    .ctct-form-embed.form_0 .ctct-form-custom .ctct-form-label,
    #email_address_label_0,
    #custom_field_string_full_name_label_0 {
        color: #07314D !important;
    }

    // Subscribe button
    div.ctct-form-embed form.ctct-form-custom button.ctct-form-button {
        padding: 0.75rem 1.25rem !important;
        border-radius: 100rem !important;
        box-shadow: 0 0 6px 0 rgba(157,96,212,0.5) !important;
        border: solid 3px transparent !important;
        background-image: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0)),linear-gradient(89.7deg, #6AC8AB 3.12%, #1E96FC 99.7%) !important;
        background-origin: border-box !important;
        background-clip: content-box, border-box !important;
        box-shadow: 2px 1000px 1px #fff inset !important;
        font-size: 1.125rem !important;
    }

    .ctct-form-button {
        grid-column: 3;

        @include media('<=tablet') {
            grid-row: 4;
            justify-self: center;
            grid-column: 1;
        }
    }

    .ctct-form-embed.form_0 .ctct-form-custom .ctct-form-button:hover {
        box-shadow: none !important;
        color: white !important;
        border: none !important;
    }

    // GDPR 
    #gdpr_text {
        //grid-row: 2;
        //grid-column: 1 / span 2;
        display: none;

        //@include media('<=tablet') {
           // grid-row: 3;
           // grid-column: 1;
           // justify-self: center;
            //text-align: center !important;
        //}
      }

      div.ctct-form-embed div.ctct-form-defaults p.ctct-form-footer {
        display: none !important;
      }
}

.paypal {
    margin: 0 auto;
    padding: 45px;
    width: 100%;
    max-width: 550px;
    background-color: #E0E8EE !important;
    border-radius: 12px;
    font-family: 'Source Sans Pro', Helvetica, serif;
    color: $dark-blue;

    #ppform select {
        margin-top: 5px;
        margin-bottom: 10px;
        padding: 15px;
        width: 100%;
        background-color: white !important;
        border-radius: 8px;
        border: none;
    }

    #dedication_intro {
        margin: 5px 10px 10px 0;
        padding: 15px;
        width: calc(50% - 5px);
        background-color: white !important;
        border-radius: 8px;
        border: none;
    }

    #dedication_memo {
        padding: 15px;
        width: calc(50% - 10px);
        background-color: white !important;
        border-radius: 8px;
        border: none;
    }

    #donation-amount {
        margin-top: 5px;
        margin-bottom: 10px;
        padding: 15px;
        width: 50%;
        background-color: white !important;
        border-radius: 8px;
        border: none;
    }
}

// Share Your Story Form

.share-your-story-form {

    .nf-form-title {
        display: none !important;
    }

    .nf-form-content {
        padding: 45px;
        background-color: #E0E8EE !important;
        border-radius: 12px !important;
    }

    .nf-form-content label {
        color: $dark-blue !important;
    }

    .nf-form-content input:not([type="button"]) {
        border-radius: 8px !important;
    }

    .nf-fu-fileinput-button {
        width: 35% !important;
        text-align: left !important;
        background-color: #C3D1DD !important;
        color: $dark-blue !important;
        position: relative;
        cursor: pointer;
        border-radius: 8px !important;

        &::after {
            position: absolute;
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.38525 0.192383V18.5667' stroke='black'/%3E%3Cpath d='M0.885986 9.86328L18.8286 9.86328' stroke='black'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            right: 12px;
            width: 20px;
            height: 20px;
            border-radius: 8px;

            @include media('<=tablet') {
                content: none;
            }
        }
    }

    .nf-form-content .list-select-wrap .nf-field-element > div, .nf-form-content input:not([type="button"]), .nf-form-content textarea {
        font-family: "Source Sans Pro",Helvetica,serif !important;
        border-radius: 8px !important;
    }

    .checkbox-wrap .nf-field-element label::after, .checkbox-wrap .nf-field-label label::after, .listcheckbox-wrap .nf-field-element label::after, .listcheckbox-wrap .nf-field-label label::after {
        background-color: #C3D1DD !important;
        border-radius: 8px !important;
        border: 1px solid rgba(7, 49, 77, 0.3) !important;
        width: 25px !important;
        height: 25px !important;
    }

    .checkbox-container.label-right label::after {
        top: 50% !important;
        transform: translate(-50%, -50%) !important;
    }

    .checkbox-container.label-right label::before {
        top: 50% !important;
        transform: translate(-50%, -50%) !important;
    }


    // form layout styles
    nf-fields-wrap {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(11, auto);
        grid-row-gap: 0;
        grid-column-gap: 25px;

        @include media('<=tablet') {
            grid-template-columns: 1fr;
            grid-template-rows: repeat(14, auto);
        }
    }

    nf-field:first-child {
        grid-row: 1;
        grid-column: 1 / span 3;

        @include media('<=tablet') {
            grid-column: 1;
        }
    }

    nf-field:nth-child(2) {
        grid-row: 1;
        grid-column: 4 / span 3;

        @include media('<=tablet') {
            grid-row: 2;
            grid-column: 1;
        }
    }

    nf-field:nth-child(3) {
        grid-row: 2;
        grid-column: 1 / span 3;

        @include media('<=tablet') {
            grid-row: 3;
            grid-column: 1;
        }
    }

    nf-field:nth-child(4) {
        grid-row: 2;
        grid-column: 4 / span 3;

        @include media('<=tablet') {
            grid-row: 4;
            grid-column: 1;
        }
    }

    nf-field:nth-child(5) {
        grid-row: 3;
        grid-column: 1 / span 6;

        @include media('<=tablet') {
            grid-row: 5;
            grid-column: 1;
        }
    }

    nf-field:nth-child(6) {
        grid-row: 4;
        grid-column: 1 / span 6;

        @include media('<=tablet') {
            grid-row: 6;
            grid-column: 1;
        }
    }

    nf-field:nth-child(7) {
        grid-row: 5;
        grid-column: 1 / span 6;

        @include media('<=tablet') {
            grid-row: 7;
            grid-column: 1;
        }



        #nf-field-23-wrap,
        #nf-field-13-wrap {
            flex-direction: column-reverse;

            .nf-field-description {
                margin-top: 10px;
            }
        }

        .nf-fu-progress {
            display: none;
        }
    }

    nf-field:nth-child(8) {
        grid-row: 6 / span 2;
        grid-column: 1;

        @include media('<=tablet') {
            display: none;
        }
    }

    nf-field:nth-child(9) {
        grid-row: 6;
        grid-column: 2 / span 5;
        display: flex;
        align-items: flex-end;

        @include media('<=tablet') {
            grid-row: 8;
            grid-column: 1;
        }

        .checkbox-container.label-right .field-wrap {
            flex-direction: column;
        }

        .label-right .nf-field-description {
            margin-left: 30px;
        }
    }

    nf-field:nth-child(10) {
        grid-row: 7;
        grid-column: 2 / span 5;

        @include media('<=tablet') {
            grid-row: 9;
            grid-column: 1;
        }

        .nf-field-label label {
            font-size: 1.125rem !important;
            font-family: $f-secondary !important;
            font-weight: 400 !important;
            line-height: 1.5 !important;
        }
    }

    nf-field:nth-child(11) {
        grid-row: 8;
        grid-column: 1 / span 6;

        @include media('<=tablet') {
            grid-row: 11;
            grid-column: 1;
        }
    }

    nf-field:nth-child(12) {
        grid-row: 9;
        grid-column: 1 / span 6;

        @include media('<=tablet') {
            grid-row: 12;
            grid-column: 1;
        }

        #nf-field-20-wrap {
            display: flex;
            flex-direction: column-reverse;

            .nf-field-description {
                margin-top: 10px;
            }
        }
    }

    nf-field:nth-child(13) {
        grid-row: 10;
        grid-column: 1 / span 6;

        @include media('<=tablet') {
            grid-row: 13;
            grid-column: 1;
        }
    }

    nf-field:nth-child(14) {
        grid-row: 11;
        grid-column: 1 / span 6;
        justify-self: center;

        @include media('<=tablet') {
            grid-row: 14;
            grid-column: 1;
        }

        #nf-field-22,
        #nf-field-14 {
            font-weight: 400 !important;
            color: $dark-blue !important;
            text-decoration: none;
            display: inline-block;
            margin-top: 25px !important;
            padding: 0.5rem 1.25rem !important;
            border-radius: 100rem !important;
            box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5) !important;
            border: solid 3px transparent !important;
            background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(89.7deg, #6AC8AB 3.12%, #1E96FC 99.7%) !important;
            background-origin: border-box !important;
            background-clip: content-box, border-box !important;
            box-shadow: 2px 1000px 1px #fff inset !important;
            font-size: 1.125rem !important;

            &:hover {
                box-shadow: none !important;
                color: white !important;
            }
        }
    }
}


// Standard Ninja form styles

.nf-form-cont {

    max-width: 1000px !important;
    margin: 0 auto !important;

    .nf-form-title {
        h3 {
            @extend .is-style-heading-sm;
            color: $dark-blue !important;
        }
    }

    .nf-before-form-content {
        padding: 5px 5px 10px !important;
    }

    .file_upload-wrap .nf-field-element {
        text-align: left !important;
    }

    .nf-fu-button-cancel {
        display: none !important;
    }

    .nf-fu-progress {
        display: none !important;
    }

    .nf-form-fields-required {
        display: none !important;
    }

    .nf-form-content {
        padding: 45px;
        background-color: #E0E8EE !important;
        border-radius: 12px !important;
    }

    .nf-form-content input.ninja-forms-field {
        height: 50px;
    }

    .nf-form-content label {
        color: $dark-blue !important;
    }

    .nf-form-content input:not([type="button"]) {
        border-radius: 8px !important;
        background: #fff !important;
        border: 1px solid #c4c4c4;
        border-radius: 0;
        box-shadow: none;
        color: $dark-blue !important;
        transition: all .5s;
    }

    .nf-form-content select.ninja-forms-field {
        color: $dark-blue !important;
    }

    .nf-form-content .list-select-wrap > div select {
        background: #fff !important;
  border: 1px solid #c4c4c4;
    }

    .nf-form-content .list-select-wrap .nf-field-element > div, .nf-form-content input:not([type="button"]), .nf-form-content textarea {
        font-family: "Source Sans Pro",Helvetica,serif !important;
    }

    /*.label-above .nf-field-element, .label-below .nf-field-element {
        text-align: center;
    }
    */

    .ninja-forms-req-symbol {
        display: none !important;
    }

    .nf-form-content button, .nf-form-content input[type="button"], .nf-form-content input[type="submit"] {
        font-weight: 400 !important;
        color: #07314D !important;
        text-decoration: none;
        display: inline-block;
        margin-top: 25px !important;
        padding: 0.5rem 1.25rem !important;
        border-radius: 100rem !important;
        box-shadow: 0 0 6px 0 rgba(157,96,212,0.5) !important;
        border: solid 3px transparent !important;
        background-image: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0)),linear-gradient(89.7deg, #6AC8AB 3.12%, #1E96FC 99.7%) !important;
        background-origin: border-box !important;
        background-clip: content-box, border-box !important;
        box-shadow: 2px 1000px 1px #fff inset !important;
        font-size: 1.125rem !important;
        cursor: pointer;

        &:hover {
            box-shadow: none !important;
            color: white !important;
        }

        &:focus,
        &:focus-visible {
            outline: 5px auto Highlight !important;
            outline: 5px auto -webkit-focus-ring-color !important;
        }
    }

    // submitted message
    .nf-response-msg {
        max-width: 1000px !important;
        margin: 0 auto !important;
        display: inherit !important;
    }
}
  