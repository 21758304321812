//Global Variables
$min_width: 20rem; // 320px
$max_width: 87.5rem; // 1400px
$min_font: 1rem;
$max_font: 4.2187rem; //12.5

$f-primary: 'raleway', sans-serif;
$f-secondary: 'Source Sans Pro', Helvetica, serif;

:root {
    font-size: 16px;
}

body {
    $min_font: 1.125rem;
    $max_font: 1.125rem;
    @include fluid-type($min_width, $max_width, $min_font, $max_font);
    font-family: $f-secondary;
    font-weight: 400;
    line-height: 1.65;
}

.flex-xl,
.is-style-flex-xl {
    $min_font: 2.5rem;
    $max_font: 12.5rem;
    
    @include fluid-type($min_width, $max_width, $min_font, $max_font);
    font-family: $f-primary;
    font-weight: 900;
    line-height: 1.2;
    //margin-bottom: 25px;
} 

.heading-xl,
.is-style-heading-xl {
    $min_font: 1.75rem;
    $max_font: 4.375rem;
    
    @include fluid-type($min_width, $max_width, $min_font, $max_font);
    font-family: $f-primary;
    font-weight: 300;
    line-height: 1.2;
    //line-height: 5.06rem;  
    //margin-bottom: 25px;
}

.heading-l,
.is-style-heading-l {
    $min_font: 1.125rem;
    $max_font: 3.125rem;
    
    @include fluid-type($min_width, $max_width, $min_font, $max_font);
    font-family: $f-primary;
    font-weight: 300;
    line-height: 1.2;
    //line-height: 3.38rem;  
    //margin-bottom: 25px;
}

.heading-sm,
.is-style-heading-sm {
    //$min_font: 1.25rem;
    $min_font: 1rem;
    $max_font: 2.5rem;
    
    @include fluid-type($min_width, $max_width, $min_font, $max_font);
    font-family: $f-primary;
    font-weight: 500;
    line-height: 1.2;
    //margin-bottom: 25px;
}

.heading-sm-header {
    //$min_font: 1.25rem;
    $min_font: 1rem;
    $max_font: 2rem;
    
    @include fluid-type($min_width, $max_width, $min_font, $max_font);
    font-family: $f-primary;
    font-weight: 500;
    line-height: 1.2;
    //margin-bottom: 25px;
}

.heading-sm--bold,
.is-style-heading-sm--bold {
    $min_font: 1.25rem;
    $max_font: 1.875rem; 
    
    @include fluid-type($min_width, $max_width, $min_font, $max_font);
    font-family: $f-primary;
    font-weight: 800;
    line-height: 1.2;
    //margin-bottom: 25px;
}

.heading-x-sm,
.is-style-heading-x-sm {
    $min_font: 1.25rem;
    $max_font: 1.5rem; //1.5
    
    @include fluid-type($min_width, $max_width, $min_font, $max_font);
    font-family: $f-primary;
    font-weight: 600;
    line-height: 1.2;
    //margin-bottom: 25px;
}

.p-large--bold,
.is-style-p-large--bold {
    $min_font: 1.125rem;
    $max_font: 1.5rem;
    
    @include fluid-type($min_width, $max_width, $min_font, $max_font);
    font-family: $f-primary;
    font-weight: 600;
    line-height: 1.5;
    //margin-bottom: 25px;
}

.p-large {
    $min_font: 1.125rem;
    $max_font: 1.5rem;
    
    @include fluid-type($min_width, $max_width, $min_font, $max_font);
    font-family: $f-secondary;
    font-weight: 400;
    line-height: 1.5;
    //margin-bottom: 25px;
}

.p-small {
    $min_font: 1.125rem;
    $max_font: 1.125rem;
    
    @include fluid-type($min_width, $max_width, $min_font, $max_font);
    font-family: $f-secondary;
    font-weight: 400;
    line-height: 1.5;
    //margin-bottom: 25px;
}

.p-small--bold {
    $min_font: 1.125rem;
    $max_font: 1.875rem;
    
    @include fluid-type($min_width, $max_width, $min_font, $max_font);
    font-family: $f-primary;
    font-weight: 900;
    line-height: 1.5;
    //margin-bottom: 25px;
}

.btn {
    $min_font: 1.25rem;
    $max_font: 1.25rem;
    
    @include fluid-type($min_width, $max_width, $min_font, $max_font);
    font-family: $f-secondary;
    font-weight: 400;
    //margin-bottom: 25px;
}

.caption {
    $min_font: 0.875rem;
    $max_font: 0.875rem;
    
    @include fluid-type($min_width, $max_width, $min_font, $max_font);
    font-family: $f-primary;
    font-weight: 400;
    //margin-bottom: 25px;
}

// headline block styles
.heading-l--blue {
    color: $dark-blue !important;
}

.heading-l--white {
    color: #fff;
}

.heading-l {

    // .heading-l--gray-blue
    &.heading-l--gray-blue {
        color: $brown-gray;

        span {
            color: $dark-blue;
        }
    }

    // .heading-l--white-blue
    &.heading-l--white-blue {
        color: #ffffff;

        span {
            color: $dark-blue;
        }
    }
}


.all-caps {
    text-transform: uppercase;
}

dl {
    dt {
        @extend .p-small;
    }
}

.screen-reader-text{
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}

.heading-xl,
.is-style-heading-xl,
.heading-l,
.is-style-heading-l {

    @include media('<=tablet') {
        text-align: center !important;
    }
}