/**
** Popup
**/

.popup {
    padding: 35px 45px;
    max-width: 599px;
    background: $light-blue;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
    border-radius: 18px;
    position: absolute;
    right: 0;
    bottom: -230px;
    z-index: 2;

    &::before {
        width: 70px;
        height: 60px;
        position: absolute;
        top: -21px;
        left: -33px;
        background-size: auto 100%;
        background-repeat: no-repeat;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg width='71' height='65' viewBox='0 0 71 65' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.9686 0C7.21392 0 0 10.2801 0 18.7119C0 36.9441 20.7227 53.8667 35.35 64.64C49.9773 53.8667 70.7 36.9433 70.7 18.7119C70.7 10.2801 63.4867 0 51.7314 0C45.1664 0 39.5583 5.23416 35.35 10.2061C31.1417 5.23365 25.5328 0 18.9686 0Z' fill='%239FC86C'/%3E%3C/svg%3E%0A");
    } 

    .popup-close {
        position: absolute;
        right: 45px;
        display: block;
        
        span {
            display: block;
            height: 4px;
            width: 40px;
            background: #9FC86C;
            border-radius: 16px;
    
        }

        span:first-child {
            transform: translate(0px, 1px) rotate(-45deg);
        }

        span:last-child {
            transform: translate(0px, -3px) rotate(45deg);
        }
    }
}

/**
** Registration Popup
**/

.registration-popup {
    max-width: 950px;
    margin: 0 auto;
    padding: 65px 10%;
    position: relative;
    color: white;
    text-align: center;

    .heading-sm {
        margin-bottom: 10px;
    }

    p {
        margin: 20px 0;
    }

    .signup-form {
        display: flex;
        align-items: center;
        justify-content: center;

        .signup-select {
            -moz-appearance:none;
            -webkit-appearance:none; 
            appearance:none;
            background: url("~/../../../assets/images/arrow-down.png") no-repeat right #ddd;
            background-position-x: 240px;
            margin-right: 26px;
            padding-left: 22px;
            border-radius: 71px;
            border: none;
            width: 280px;
            height: 50px;
            position: relative;
            font-weight: 500;
        }

        button {
            text-transform: uppercase;
            font-weight: 500;

            &.btn.btn--primary {
                margin: 0;
            }
        }

    }

    .donor-msg {
        font-size: 14px;

        a {
            font-size: 14px;
            color: white;
        }
    }
}

.lity-container {
    
    .lity-close {
        position: absolute;
    }
}