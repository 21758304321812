

// Single News Article

.news-single-heading {
  max-width: 1200px;
  margin: 50px auto 20px auto;
  padding: 0;
  color:$brown-gray;
  line-height: 1.5em;
  @include media('<=1200px'){
     padding: 0 3%;
  }
  .news-wrapper {
      width: 100%;
      max-width: 1240px;
      margin:0 auto;
      display: flex;

      @include media('<=1000px'){
          flex-direction: column;
      }
  }

  .news-highlight {
    // width:75%;
    display: flex;
    align-items: center;
    flex: 1;

    @include media('<=1000px'){
        width:100%;
    }
    @include media('<=768px'){
      flex-direction: column;
      width: 100%;
      padding: 0;
      display: flex;
      justify-content: center;
    }

      // div {
      //     flex: 0 1 auto;
      //     &:first-child {
      //         margin-right: 60px;

      //         @include media('<=1000px'){
      //             margin-right: 0px;
      //         }
      //     }
      // }

      .img-wrapper {
        min-width: 43%;
        max-width: 43%;
        padding-right:4.1%;
        margin-right: 4.1%;
        align-self: flex-start;
        border-right: 2px solid #AACC6F;
        @include media ('<=768px'){
          width: 100%;
          max-width: 100%;
          padding: 0 3%;
          display: flex;
          justify-content: center;
          margin: 0 auto;
          border-right:none;
        }
    }

    .news-content {
      // width: 54%;
      //margin-right: 3%;
      //padding-right: 3%;
      color:$brown-gray;
      line-height: 1.5em;
      
      height: 100%;
      padding-top: 30px;
      padding-bottom: 30px;
      flex: 1;
      justify-content: center;
     display: flex;
      flex-direction: column;
      @include media('<=768px'){
        margin: 0;
        padding:30px 0;
        text-align: center;
        h4.heading-l{
          text-align: left !important;
        }

       
        
      }
      .date{
        order:-1;
      }
      @include media('<=1000px'){
          border-top: none;
          width: 100%;
      }

      .location{
        color: $dark-blue;
      }
    }
  }


  .social-share {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #000;
    @include media('<=1000px') {
        flex-direction: column;
    }
    @include media('<=768px') {
      flex-direction: row;
      font-size: 16px;
      padding: 0 2%;
      border-bottom: 3px solid #AACC6F;
      margin-top: 16px;
    }
    
    p {
        font-family: $f-secondary;
        padding-right: 50px;

        @include media('<=1000px') {
            padding-right: 0;
        }
        @include media('<=768px') {
            font-size:16px;
        }
    }

    .social-share-icons {
      @include media ('<=768px'){
        margin-left: auto;
      }
        a {
            text-decoration: none;

            &:not(:last-child) {
                margin-right: 32px;
                @include media ('<=768px'){
                  margin-right: 16px;
                }
            }

            svg {
                display: inline-block;
                width: auto;
                height: 29px;
                @include media ('<=768px'){
                  height:19px;
                }
            }
        }
    }
  }


}


.news-single-content{
  margin: 0 auto;
  padding: 0;
  max-width: 1200px;
  color: #444;
  line-height: 1.5em;
  @include media('<=1200px'){
    padding: 0 3%;
 }
}

.boilerplate{
  max-width: 1200px;
  margin: 0 auto;
  @include media('<=1200px'){
    padding: 0 3%;
 }
}