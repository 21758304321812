.outercontainer {
    width: 100%;
}

.innercontainer {
    margin: 0 auto;
    padding: 0 5%;
    max-width: 1440px;

    &-padding {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    &--1440 {
        max-width: 1440px;
    }

    &--1200 {
        max-width: 1200px;
    }

    &--1000 {
        max-width: 1200px;
    }
}

.container--small {
    width: 100%;
    
    @include media('<=1000px') {
        max-width: 350px;
        margin: 0 auto;
    }

}
/**
*** BACKGROUNDS
**/

.linear-gradient {
    background: linear-gradient(0deg, #29C0B4 13%, #7DC784 33%, #ABCC75 50%, #fff 94%);
}

.linear-gradient--2 {
    &.tds {
        background-image:
        linear-gradient(
          to top, 
          $tn-green,
          $tn-green 30%,
          #9FC86C 30%,
          #9FC86C 70%,
          $tn-green 70%
        );


        .card {

            .card-excerpt {
                color: white;
            }
        }
    }

    &.sierra {
        background-image:
        linear-gradient(
          to top, 
          #C3CDD3,
          #C3CDD3 30%,
          #9EACB5 30%,
          #9EACB5 70%,
         #C3CDD3 70%
        );

        .card {
            background-color: #1C6897;

            .card-excerpt {
                color: white;
            }
        }
    }

    &.nm {
        background-image:
        linear-gradient(
          to top, 
          $pale-peach,
          $pale-peach 30%,
            $rose-peach 30%,
            $rose-peach 70%,
          $pale-peach 70%
        );

        .card {
            background-color: #D44214;


            .card-excerpt {
                color: white;
            }
        }

    }

    

}
