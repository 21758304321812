.newslisting{

  max-width: 1400px;
  margin: 0 auto;
  padding: 0 5%;

  .newslisting-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;

    @include media('<=tablet') {
      justify-content: center;
    }
  }

  &.tds{
    .row{
      display: flex;
      width: 100%;

      &:not(:last-child),
      &:not(:only-child) {
        margin-bottom: 25px;
      }

      @include media('<=tablet') {
        flex-direction: column;
      }

      .entry{
        //flex: 1 1 50%;
        border-radius: 12px;
        overflow: hidden;

        &:not(:last-child) {
          margin-right: 20px;

          @include media('<=tablet') {
            margin-right: 0px;
            margin-bottom: 25px;
          }
        }

        * {
          margin-bottom: 10px;
        }

        h2 {
          @extend .heading-x-sm;
        }

        .btn--float {
          color: $dark-blue;
          background-color: white;
          padding: 15px;
          border-radius: 0 0 12px 0;
          width: 150px;
          position: absolute;
          right: -150px;
          bottom: 0;
          margin: 0;
          visibility: hidden;
        }

        .pubdate {
          font-size: 14px;
          color: #fff;
        }

        a{
          text-decoration: none;

          &:first-child {
            width: 100%;
            height: 100%;
            display: inline-block;
            padding: 45px;
            border-radius: 12px;
            position: relative;

            @include media('<=tablet') {
              margin-bottom: 0;
            }

            &:hover {

              .btn--float {
                color: $dark-blue;
                background-color: white;
                padding: 15px;
                border-radius: 0 0 12px 0;
                width: 150px;
                position: absolute;
                right: 0;
                bottom: 0;
                margin: 0;
                visibility: visible;
                transition: right 0.5s ease-in-out;
              }
            }
          }
        }

        .img + a{
          background-color: $teal;
        }


      }

      .entry--text {
        background-color: $dark-blue;
        flex: 1 1 40%;

        a {
          color: white;
          background-color: $dark-blue;

          &:first-child {
            background-color: $dark-blue;
          }
        }
      }

      .entry--img {
        background-color: $teal;
        flex: 1 1 60%;

        &:nth-child(2n+1) {
          background-color: $light-blue !important;
        }


        .img {
          margin: 0 !important;
          padding: 0 !important;
          display: flex !important;
          align-items: center;

          @include media('<=tablet') {
            flex-direction: column;
          }

          img {
            width: calc(40% - 30px) !important;
            margin-right: 30px !important;
            margin-bottom: 0;
            height: 100%;
            object-fit: cover;

            @include media('<=tablet') {
              width: 100% !important;
              margin-right: 0 !important;
            }
          }

          .content {
            width: calc(60% - 30px);
            padding: 45px 0 !important;

            @include media('<=tablet') {
              width: 100%;
              padding: 45px 5% !important;
            }
          }
        }

        a:last-child {
          //width: calc(60% - 30px);
          //margin: 45px 0;
        }

        .pubdate {
          color: $dark-blue !important;
        }
      }
    }
  }

  &.sierra {
    .row{
      display: flex;
      width: 100%;

      &:not(:last-child),
      &:not(:only-child) {
        margin-bottom: 25px;
      }

      @include media('<=tablet') {
        flex-direction: column;
      }

      .entry{
        //flex: 1 1 50%;
        border-radius: 12px;
        overflow: hidden;

        &:not(:last-child) {
          margin-right: 20px;

          @include media('<=tablet') {
            margin-right: 0px;
            margin-bottom: 25px;
          }
        }

        * {
          margin-bottom: 10px;
        }

        h2 {
          @extend .heading-x-sm;
        }

        .btn--float {
          color: $dark-blue;
          background-color: white;
          padding: 15px;
          border-radius: 0 0 12px 0;
          width: 150px;
          position: absolute;
          right: -150px;
          bottom: 0;
          margin: 0;
          visibility: hidden;
        }

        .pubdate {
          font-size: 14px;
          color: #fff;
        }

        a{
          text-decoration: none;

          &:first-child {
            width: 100%;
            height: 100%;
            display: inline-block;
            padding: 45px;
            border-radius: 12px;
            position: relative;

            @include media('<=tablet') {
              margin-bottom: 0;
            }

            &:hover {

              .btn--float {
                color: $dark-blue;
                background-color: white;
                padding: 15px;
                border-radius: 0 0 12px 0;
                width: 150px;
                position: absolute;
                right: 0;
                bottom: 0;
                margin: 0;
                visibility: visible;
                transition: right 0.5s ease-in-out;
              }
            }
          }
        }

        .img + a{
          background-color: #E9F0F4;
        }


      }

      .entry--text {
        background-color: #1C6897;
        flex: 1 1 40%;

        a {
          color: white;
          background-color: #1C6897;

          &:first-child {
            background-color: #1C6897;
          }
        }
      }

      .entry--img {
        background-color: #E9F0F4;
        flex: 1 1 60%;

        &:nth-child(2n+1) {
          background-color: #AACA6F !important;
        }


        .img {
          margin: 0 !important;
          padding: 0 !important;
          display: flex !important;
          align-items: center;

          @include media('<=tablet') {
            flex-direction: column;
          }

          img {
            width: calc(40% - 30px) !important;
            margin-right: 30px !important;
            margin-bottom: 0;
            height: 100%;
            object-fit: cover;

            @include media('<=tablet') {
              width: 100% !important;
              margin-right: 0 !important;
            }
          }

          .content {
            width: calc(60% - 30px);
            padding: 45px 0 !important;

            @include media('<=tablet') {
              width: 100%;
              padding: 45px 5% !important;
            }
          }
        }

        a:last-child {
          //width: calc(60% - 30px);
          //margin: 45px 0;
        }

        .pubdate {
          color: $dark-blue !important;
        }
      }
    }
  }

  &.nm {
    .row{
      display: flex;
      width: 100%;

      &:not(:last-child),
      &:not(:only-child) {
        margin-bottom: 25px;
      }

      @include media('<=tablet') {
        flex-direction: column;
      }

      .entry{
        //flex: 1 1 50%;
        border-radius: 12px;
        overflow: hidden;

        &:not(:last-child) {
          margin-right: 20px;

          @include media('<=tablet') {
            margin-right: 0px;
            margin-bottom: 25px;
          }
        }

        * {
          margin-bottom: 10px;
        }

        h2 {
          @extend .heading-x-sm;
        }

        .btn--float {
          color: $dark-blue;
          background-color: white;
          padding: 15px;
          border-radius: 0 0 12px 0;
          width: 150px;
          position: absolute;
          right: -150px;
          bottom: 0;
          margin: 0;
          visibility: hidden;
        }

        .pubdate {
          font-size: 14px;
          color: #fff;
        }

        a{
          text-decoration: none;

          &:first-child {
            width: 100%;
            height: 100%;
            display: inline-block;
            padding: 45px;
            border-radius: 12px;
            position: relative;

            @include media('<=tablet') {
              margin-bottom: 0;
            }

            &:hover {

              .btn--float {
                color: $dark-blue;
                background-color: white;
                padding: 15px;
                border-radius: 0 0 12px 0;
                width: 150px;
                position: absolute;
                right: 0;
                bottom: 0;
                margin: 0;
                visibility: visible;
                transition: right 0.5s ease-in-out;
              }
            }
          }
        }

        .img + a{
          background-color: #E9F0F4;
        }


      }

      .entry--text {
        background-color: $dark-blue;
        flex: 1 1 40%;

        .content {
          color: #fff;
        }

        &:nth-child(2n+1) {
          background-color: $grenadier;

          .content {
            color: #fff;
          }
        }
      }

      .entry--img {
        background-color: $light-blue;
        flex: 1 1 60%;

        &:nth-child(2n+1) {
          background-color: $teal-blue !important;
        }


        .img {
          margin: 0 !important;
          padding: 0 !important;
          display: flex !important;
          align-items: center;

          @include media('<=tablet') {
            flex-direction: column;
          }

          img {
            width: calc(40% - 30px) !important;
            margin-right: 30px !important;
            margin-bottom: 0;
            height: 100%;
            object-fit: cover;

            @include media('<=tablet') {
              width: 100% !important;
              margin-right: 0 !important;
            }
          }

          .content {
            width: calc(60% - 30px);
            padding: 45px 0 !important;

            @include media('<=tablet') {
              width: 100%;
              padding: 45px 5% !important;
            }
          }
        }

        a:last-child {
          //width: calc(60% - 30px);
          //margin: 45px 0;
        }

        .pubdate {
          color: $dark-blue !important;
        }
      }
    }
  }

  .newslisting-bottom {


    @include media('<=tablet') {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}