.billboardvariant {
  margin-bottom: 0 !important;
  display: flex;
  margin-bottom: 1.75em;
  box-sizing: border-box;
  flex-wrap: wrap !important;
  align-items: center;

  &--reverse {
    flex-direction: row-reverse;

    .col:first-child {
      margin: 50px 0em 50px 2em !important;
      flex-basis: 66.66%;
    }

  }

  &.padded{
    padding:60px 0;
    @media (max-width: 781px) {
      padding:30px 0;
    }
  }

  @media (min-width: 782px) {
    flex-wrap: nowrap !important;
  }

  .col:first-child {
    margin: 50px 2em 50px 0;
    flex-basis: 66.66%;

    @media (max-width: 781px) {
      flex-basis: 100% !important;
    }
  }

  .col:last-child {
    flex-basis: 33.33%;
    align-self: flex-end;

    @media (max-width: 781px) {
      flex-basis: 100% !important;
    }

    &.align-bottom { align-self: flex-end;}
    &.align-center { align-self: center;}
    &.align-top { align-self: flex-start;}

    &.stretch{

      align-self: stretch;
      // use a background image on desktop, hide on mobile showing the actual image element. 
      .image-wrap{
        height: 100%;
        display: flex;
        //background-color: cornflowerblue;
        background-image: var(--bgimage);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @media (max-width: 781px) {
          background-image:none;
          max-width: 500px;
          margin: 0 auto;
        }
        img{ 
          display: none;

          @media (max-width: 781px) {
            width: 500px;
            display: block;
          }
        }
      }
    }




    .image-wrap{
      height: 100%;
      display: flex;
    }
    
    img {
      display: inline-block;
      vertical-align: bottom;
      height: auto;
      width: max-content;
      margin: 0 auto;

      @media (max-width: 781px) {
        width: 500px;
      }
    }
  }
}