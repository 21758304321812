.leadership-cards {
    padding: 100px 0;

    //theme settings
    &.sierra {
        .card {

            .img-wrapper {
                background-color: #00A6DF;
            }
        }
    }

    &.nm {
        .card {

            .img-wrapper {
                background-color: $grenadier;
            }
        }
    }

    // card styles

    .card-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
        grid-gap: 60px 20px;
    }

    .card {
        list-style-type: none;

        .img-wrapper {
            border-radius: 10px;
            padding: 20px;
        }

        img {
            width: 100%;
            height: 320px;
            object-fit: cover;
            border-radius: 10px;
        }

        .heading-sm {
            margin: 32px 0 10px;
            font-weight: 300;
        }

        .p-small {
            margin: 0;
        }
    }
}