#dcids {
    .top-bar-nav {
        background-color: $dark-purple;

        a {
            background: linear-gradient($peach, $peach) bottom/0% 2px no-repeat;

            &:hover {
                background-size: 100% 3px;
              }
        }
    }

    .nav {
        .nav-links .sub-menu {
            background-color: $dark-purple;
        }

        .hamburger span {
            background: $peach;
        }

        .nav-mobile .mobile li a {
            color: #000;
            background: linear-gradient($peach, $peach) bottom/0% 2px no-repeat;

            &:hover {
                background-size: 100% 3px;
              }
        }

        .nav-mobile .mobile li.menu-item-has-children a::after {
            background-image: url("data:image/svg+xml,%3Csvg width='14' height='28' viewBox='0 0 14 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 1L12 14L2 27' stroke='%23342B49' stroke-width='3'/%3E%3C/svg%3E");
        }

        .nav-mobile .mobile li:nth-last-child(3) {
            background-color: inherit;
        }

        .nav-mobile .mobile li:nth-last-child(2) {
            background-color: $peach;
        }

        .nav-mobile .mobile li:nth-last-child(1) {
            background-color: $purple;

            a {
                color: #fff;
            }
        }

        .nav-mobile .bottom {
            background-color: $dark-purple;
        }
    }

    .btn.btn--small,
    .btn.btn--primary,
    .btn.btn--is-style-sm,
    .btn.btn--is-style-lg {
        border: 3px solid $peach;
        background-clip: border-box;
        background-image: unset;
        color: $dark-purple;

        &:hover {
            box-shadow: none;
            color: white;
            border: solid 3px $peach;
            background-color: $purple;
            background-image: unset;
            background-clip: border-box;
        }
    }

    .btn--arrow::after, .btn--is-style-arrow::after {
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='28' viewBox='0 0 14 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 1L12 14L2 27' stroke='%23D1998A' stroke-width='3'/%3E%3C/svg%3E");
    }

    .linear-gradient--2 {
        background-image:
        linear-gradient(
          to top, 
          $purple,
          $purple 30%,
            $dark-purple 30%,
            $dark-purple 70%,
          $purple 70%
        );

        .card {
            background-color: $dark-purple;


            .card-excerpt {
                color: white;
            }
        }
    }
    .popup {

        &::before {
            background-image: url("data:image/svg+xml,%3Csvg width='71' height='65' viewBox='0 0 71 65' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.9686 0C7.21392 0 0 10.2801 0 18.7119C0 36.9441 20.7227 53.8667 35.35 64.64C49.9773 53.8667 70.7 36.9433 70.7 18.7119C70.7 10.2801 63.4867 0 51.7314 0C45.1664 0 39.5583 5.23416 35.35 10.2061C31.1417 5.23365 25.5328 0 18.9686 0Z' fill='%23D1998A'/%3E%3C/svg%3E%0A");
        }

        .popup-close span {
            background: $peach;
        }
    }

    #modal-popup {
        background: $dark-purple;
    }
}

#nm {
    .top-bar-nav {

        a {
            background: linear-gradient($grenadier, $grenadier) bottom/0% 2px no-repeat;

            &:hover {
                background-size: 100% 3px;
              }
        }
    }
}