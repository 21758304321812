.billboard {
    margin-bottom: 0 !important;
    display: flex;
    margin-bottom: 1.75em;
    margin-right: 2em;
    box-sizing: border-box;
    flex-wrap: wrap !important;
    align-items: center;

    &--reverse {
        flex-direction: row-reverse;

        .col:first-child {
            margin: 50px 0em 50px 2em !important;
            flex-basis: 66.66%;
        }

    }

    @media (min-width: 782px) {
        flex-wrap: nowrap !important;
    }

    .col:first-child {
        margin: 50px 2em 50px 0;
        flex-basis: 66.66%;

        @media (max-width: 781px) {
            flex-basis: 100% !important;
        }
    }

    .col:last-child {
        flex-basis: 33.33%;
        align-self: flex-end;

        @media (max-width: 781px) {
            flex-basis: 100% !important;
        }

        &.align-bottom {
            align-self: flex-end;
        }

        &.align-center {
            align-self: center;
        }

        &.align-top {
            align-self: flex-start;
        }

        img {
            display: inline-block;
            vertical-align: bottom;
            width: 600px;
            height: auto;

            @media (max-width: 781px) {
                width: 500px;
            }
        }
    }
  }


  .billboard-image {
    background-image: linear-gradient(0deg, rgba(52,43,73,0.7) 38%, rgba(0,0,0,0.7) 64%) ,var(--bg-image);
    background-repeat: no-repeat;
    background-size: cover;

    .billboard-image-container {
        max-width: 1400px;
        margin: 0 auto;
        padding: 50px 25px 50px;
        display: flex;


        @include media('<=tablet') {
            flex-direction: column;
        }

        .col {

            &:first-child {
                width: 40%;

                @include media('<=tablet') {
                    width: 100%;
                }
            }

            &:last-child {
                width: 60%;

                @include media('<=tablet') {
                    width: 100%;
                }
            }

            .content {
                color: white;
            }
        }
    }
  }

  .billboard-testimony {
    overflow: hidden;

    .billboard-testimony-container {
        max-width: 1400px;
        margin: 0 auto;
        display: flex;

        @include media('<=tablet') {
            flex-direction: column;
        }

        .col {

            &.bg-img {
                background-image: var(--bg-image);
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                width: 100%;
                height: 100%;

                @include media('<=tablet') {
                    background-image: none;
                }
            }

            &:first-child {
                width: 40%;
                height: auto;
                position: relative;

                display: flex;
                justify-content: center;
                align-items: flex-end;

                @include media('<=tablet') {
                    width: 100%;
                }
            }

            &:last-child {
                width: 60%;
                padding: 50px;

                @include media('<=tablet') {
                    width: 100%;
                }
            }
        }

        .testimony-image {
            width: 100%;

            img {
                width: 100%;
                object-fit: cover;
            }
        }


        .button-wrapper {
            width: 250px;
            height: 120px;
            background-color: white;
            display: inline-block;
            border-top-left-radius: 125px;
            border-top-right-radius: 125px;


            @include media('<=tablet') {
                position: absolute;
                content: '';
                bottom: 0;
                left: 50%;
                transform: translate(-50%, 0%);
                width: 160px;
                height: 100px;
            }
        
            a {
              @extend .p-large;
              width: inherit;
              height: inherit;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              text-align: center;
              color: $dark-blue;
              text-decoration:  none;
              position: relative;
              z-index: 3;        
        
              span:first-child {
                  @extend .p-small;
                  margin-top: 25px;
                  font-weight: 400;
        
                  @include media('<=900px') {
                    margin-top: 0;
                    font-family: $f-primary;
                    font-size: 16px;
                    font-weight: 800;
                  }
        
              }
        
              span:last-child {
                  margin-bottom: 0;
                  text-decoration: none; 
        
                  @include media('<=900px') {
                    margin-top: 0;
                    font-size: 16px;
                    font-weight: 800;
                  }
              }
            }
        }
    }
  }