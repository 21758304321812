.leadership{ //block


    .postheader{
      //background: linear-gradient(to right, rgba(255,62,65,1) 0%,rgba(255,62,65,1) 14%,rgba(255,62,65,1) 14%,rgba(163,32,53,1) 14%,rgba(163,32,53,1) 84%,rgba(255,152,150,1) 84%,rgba(255,152,150,1) 100%);
     // background: linear-gradient(to right, #349425 0%,#349425 14%,#349425 14%,#2E473A 14%, #2E473A 84%,#3CAD2B 84%,#3CAD2B 100%);
    }
    .profile-wrap{
      display: none;
    }
    .profiles{
      display: block;//override flex
    }
  

    .leadershipnav
    {
      padding: 0 40px;
      margin-top: 35px;
      margin-bottom: 45px;
      
      .slide
      {
        padding:0 20%;
        a {
          display: block;
          padding:10px;
          transition:opacity .2s;
          opacity: .5;
          border-radius: 12px;
          border: 1px solid green;
          .bg
          {
            background-size: cover;
            background-position:center;           
            padding-top: 100%;         

            border-radius: 12px;
            border: 1px solid green;
          }
        }
      }
      .current .slide a
      {
        opacity: 1;
        
      }
  
      .slick-arrow{
        position: absolute;
        z-index: 5;
        top: 50%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        color:transparent;
        border: none;
        background-position: center;
        width: 40px;
        overflow: hidden;
      }
      .slick-arrow:hover{
        cursor: pointer;
      }
      .slick-next{
        background-image: url('../../assets/images/RightArrow.svg');
        right: 0;
      }
      .slick-prev{
        background-image: url('../../assets/images/LeftArrow.svg');
        left: 0;
      }
    }
  
  
  
    .slick-prev:before,
    .slick-next:before{
      content: '';//removing default arrow bd
    }
  
  
    .slick-prev .slick-prev-icon {
      visibility: hidden; // removing small arrow
    }
  
  
  
  
  }//end leadership

// DCIDS Theme only
.leadership-grid {
  margin: 50px 0;

  .leadership-grid-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 5%;

    .card {

      a {
        text-decoration: none;
      }
    }

    .card-wrapper {
      padding-top: 25px;
      display: grid;
      grid-template-columns: repeat( auto-fit, minmax(350px, 1fr) );
      grid-gap: 40px;
    }

    .img-wrapper {
      margin-bottom: 20px;
      border: 17px solid $purple;
      border-radius: 10px;

      img {
        //border-radius: 10px;
        width: 100%;
        height: 313px;
        object-fit: cover;
      }
    }

    .content {
      .heading-sm {
        margin-bottom: 10px;
      }

      p {
        margin: 0;
      }
    }
  }

}
  

// DCIDS Theme only
.popup-bio {
  padding: 15px;
  background-color: $purple;
  display: flex;
  flex-direction: row-reverse;
  //align-items: flex-start;
  max-width: 1100px;
  border-radius: 16px;


  @include media('<=1000px') {
    overflow: scroll;
    flex-direction: column;
  }

  .headeing-sm {
    color: white;
  }

  .bio-img {
    width: 35%;
    //height: 100%;

    @include media('<=1000px') {
      padding-bottom: 15px;
      width: 100%;
    }

    img {
      height: 100%;
      object-fit: cover;

      @include media('<=1000px') {
        //width: 100%;
        object-fit: none;
        height: auto;
      }
    }
  }

  .bio-content {
    margin-right: 45px;
    width: 65%;
    color: white;

    @include media('<=1000px') {
      margin-right: 0;
      width: 100%;
    }

    .bio-content-top {
      display: flex;

      .heading-sm {
        margin-right: 25px;
      }

      .job-title {
        font-size: 1.125rem;
        line-height: 1.65;
      }
    }

    p {
      font-size: 1rem;
      line-height: 1.45;
    }
  }
}
  
  
  
  