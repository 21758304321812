.donor-bio {
    // donor-bio without image settings  
    .donor-bio-wrapper {
        max-width: 820px;
        margin: 0 auto;
        text-align: center;

        .donor-info {
            justify-content: center;
        }
    }

    // donor-bio w/ image settings
    .donor-bio-wrapper--2col {
        display: grid;
        //grid-template-columns: repeat(auto-fill, minmax(400px, auto));
        grid-template-columns: minmax(min-content, 300px) 1fr;
        grid-gap: 55px;
        grid-template-rows: auto 1fr;

        @include media('<=1000px') {
            grid-template-rows: auto auto auto;
            grid-template-columns: auto;
            grid-gap: 44px;
        }
      

        .donor-bio-pic {
            grid-column: 1;
            grid-row: 1;
            border-right: 3px solid #6BA137;

            @include media('<=1000px') {
                display: flex;
                justify-content: center;
                border-right: 0;
            }

            img {
                padding-right: 55px;
                max-width: 500px;
                height: auto;
    
                @include media('<=1000px') {
                    padding-right: 0;
                    border-right: 0;
                    width: 100%;
                    object-fit: contain;
                }
            }
        }

        .social-share {
            margin-right: 55px;
            grid-column: 1;
            grid-row: 2;
            display: flex;
            align-self: flex-start;
            flex-direction: row;
            //justify-content: space-between;
            align-items: center;

            @include media('<=1000px') {
                margin-right: 0;
                padding-bottom: 44px;
                grid-column: 1;
                grid-row: 3;
                flex-direction: column;
            }
        }

        .donor-bio-content-wrapper {
            grid-column: 2;
            grid-row: 1 / span 2;

            @include media('<=1000px') {
                border-bottom: 3px solid #6BA137;
                padding: 35px 0 44px;
                grid-column: 1;
                grid-row: 2;
            }
        }
    }

    // donor-bio-content-wrapper
    .donor-bio-content-wrapper {
        padding: 35px 0 150px;
        align-self: center;

        .heading-l {

            @include media('<=1000px') {
                text-align: center;
            }
        }
    }

    // donor badges
    .donor-badges {
        padding-bottom: 50px;

        @include media('<=1000px') {
            display: flex;
            justify-content: center;
        }

        img {
            width: 113px;
            height: 113px;

            @include media('<=1000px') {
                width: 72px;
                height: 72px;
            }

            &:not(:last-child) {
                margin-right: 20px;
            }
        }

    }

    // donor info
    .donor-info {
        display: flex;
        align-items: center;

        @include media('<=1000px') {
            justify-content: center;
        }

        .donor-type {
            font-weight: 400;
        }

        span {
            margin: 0 19px;
        }
    }

    // bio-content
    .donor-bio-content {
        padding-bottom: 50px;
    }

    // bio without images
    .bio-content-bottom {
        display: flex;
        justify-content: space-between; 

        @include media('<=1000px') {
            flex-direction: column;
            justify-content: center;
            align-items: inherit;      
        }

        .btn.btn--arrow-alt {
            
            @include media('<=1000px') {
                align-self: flex-start;   
                margin-bottom: 44px;   
            }
        }

        .social-share {
            

            @include media('<=1000px') {
                padding-top: 44px;
                border-top: 3px solid #6BA137;
            }
        }
    }

    // social-share
    .social-share {
        display: flex;
        flex-direction: row;
        //justify-content: space-between;
        align-items: center;

        @include media('<=1000px') {
            flex-direction: column;
        }

        .p-large {
            font-size: $f-secondary;
            padding-right: 30px;

            @include media('<=1000px') {
                padding-right: 0;
            }
        }

        .social-share-icons {
            display: flex;
            align-items: center;

            a {
                text-decoration: none;

                &:not(:last-child) {
                    margin-right: 15px;
                }

                svg {
                    display: inline-block;
                    width: auto;
                    height: 29px;
                }
            }
        }

    }
}
