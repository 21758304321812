

.page-template-page_careers {

  @media (max-width: 768px) {
    .quote::before {
        width: 200px;
        height: 200px;
        bottom: -175px;
        top: unset;
        right: -100px;
    }
  }
  .nav {   // .nav-desktop
    &-desktop {
        flex: 1 1 auto;
        display: flex;
        justify-content: flex-end;
    
    }
  }

  .nav.dcicareers{ //overrides for link styles
    .btn.btn--primary,
    .btn.btn--primary a{
      &::after{
        height: 0px;
        background-color: transparent;
      }
    }
    .btn.btn--primary:hover,
    .btn.btn--primary:hover a{
      border-radius: 26px;
      &::after{
        height: 0px;
        background-color: transparent;
      }
    }
    a{
      text-decoration: none;
      &::after{
          position: relative;
          content: '';
          bottom: -15px;
          left: 50%;
          z-index: 55;
          transform: translate(-50%);
          width: 0;
          height: 2.5px;
          background-color: #9FC86C;
          transition: 0.5s all;
          display: block;
      }
      &:hover::after{
        width: 100%;
      }
    }
   
  }//.nav.dcicareers

  @media (max-width:780px){ 
    .reverse-mobile {//special case for 2nd instance of 2 columns with an image left.
      flex-direction: column-reverse;
    }      

    .nav-logo:hover::after{
      background-color: transparent;
    }
    .aboutus{
      display: none;
    }
  }

  .quote.dcicareers{
    .quote-container{
      padding:88px 20px;
    }
  }
  



  .heading-l,
  .is-style-heading-l {
      $min_font: 2.25rem;
      $max_font: 3.125rem;
      
      @include fluid-type($min_width, $max_width, $min_font, $max_font);
      font-family: $f-primary;
      font-weight: 300;
      line-height: 1.2;
      //line-height: 3.38rem;  
      //margin-bottom: 25px;
  }
  .heading-xl,
  .is-style-heading-xl {
      $min_font: 2.25rem;
      $max_font: 3.65rem;
      
      @include fluid-type($min_width, $max_width, $min_font, $max_font);
      font-family: $f-primary;
      font-weight: 300;
      line-height: 1.2;
      //line-height: 5.06rem;  
      //margin-bottom: 25px;
  }
  @media (max-width:780px){ 
    .p-small{
      text-align: center;
    }
  }
  @media (max-width:768px){ 
    .text-highlight {
      .heading{
        max-width: 100%;
      }
      .heading-l,
      .heading-xl{
        text-align: left;
      }
    }
    .career-intro {
      .heading-l,
      .heading-xl{
        font-size: 40px;
        text-align: left !important;
        strong{ font-size: 60px;}
      }
    }
  }
  //blocks
  @media (max-width:780px){ 
    .text-highlight{
      text-align: center;
      background-position: center;
        background-size: cover;
        background-image: var(--bg);
      .btn-container.has-text-align-left{
        text-align: center;
      }
    }
  }



} //page-template-page_careers 







.career-footer{
  font-size: 1rem;
  background-color: #07314D;
  color: white;
  overflow: hidden;
  border-top:2px solid $beau-blue;
  flex-flow: column;

  @media (max-width:768px) {
    .hide-mobile{
      display: none;
    }  
  }
  


  a{
    color: #fff;
    text-decoration: none;
    &:hover{
      text-decoration: underline;
    }
  }
  &-top{
    display: grid;
    grid-template-columns: repeat(2, 1fr) 1.5fr repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 0px;
    margin: 0 auto;
    padding: 0 5%;
    max-width: 1440px;
    text-align: center;
    .contact-showmobile{
      display: none;
      color:$dark-blue;
      a{
        color:$dark-blue;
        margin: auto;
      }
    }
    @media (max-width:950px){
      display: flex;
      flex-direction: column;
      .logo{
        order: -3;
        padding-top: 20px;
      }
      .contact-showmobile{
        order: -2;
        width: 100vw;
        padding: 30px 0;
        background-color: $beau-blue;
        margin-left: -5vw;
        margin-top: 0;
        display: flex;
        flex-flow: column;
      }
    }
    
  }
  &-bottom{
    background-color: $beau-blue;
    color:$dark-blue;
    padding:10px;
    a{
      color:$dark-blue;
      margin: auto;
    }
    .wrapper{ 
      margin: 0 auto;
      padding: 0 5%;
      max-width: 1440px;
      display: flex;
      flex-flow: row wrap;
      .col{
        flex: 1 0 33.3%;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: center;
        text-align: center;
        @media (max-width:768px){
          flex-basis: 100%;
          align-items: center;
          margin: 20px auto;
        }
      }
      .career-button{
        align-items: flex-start;
      }
      .career-contact{
        align-items: center;
      }
      .career-social{
        align-items: flex-end;
        @media (max-width:768px){
          align-items: center;         
        }
        img{
          vertical-align: text-top;
        }
      }
      .social-links{
        a{
          margin-left:10px;
        }
      }
    }
  }
}//careeer footer



