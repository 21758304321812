.cta-getinvolved{

  //themes
  &.tds{
    .word-block > .word-block-buttons a{
      &:nth-child(1){ background-color: #52C7C0; }
      &:nth-child(2){ background-color: #ACD27B; }
      &:nth-child(3){ background-color: #A8CEE5; }
    }
  }

  &.sierra{
    .word-block > .word-block-buttons a{
      &:nth-child(1){ background-color: #255C80; color: #fff;}
      &:nth-child(2){ background-color: #C3CDD3; }
      &:nth-child(3){ background-color: #00A6DF; }
    }
  }

  &.nm{
    .word-block > .word-block-buttons a{
      &:nth-child(1){ background-color: $grenadier; color: #fff;}
      &:nth-child(2){ background-color: $teal-blue; }
      &:nth-child(3){ background-color: $dark-blue; color: #fff; }
    }
  }

  &.dcids {
    .word-block > .word-block-buttons a{
      &:nth-child(1){ background-color: $dark-purple; color: #fff;}
      &:nth-child(2){ background-color: $peach; }
      &:nth-child(3){ background-color: #C3D1DD;}
    }
  }

  
  .word-block {
    width: 100%;


    .words {
        background-repeat: no-repeat;
        height: 560px;
        position: relative;
        overflow: hidden;

        background-image: linear-gradient(90deg, rgba(255,255,255,0) 31%, rgba(255,255,255,1) 67%) var(--bg);

        @include media('<=tablet') {
          background-image: linear-gradient(90deg, rgba(255,255,255,0) 31%, rgba(255,255,255,1) 95%) var(--bg);
          background-position: bottom;
          background-size: cover;
        }

        p {
            margin: 0;
            position: absolute;

            &:first-child {

              @include media('<=tablet') {
                top: 0 !important;
              }
            }
        }
    }
    
    .word-block-buttons {
        width: 100%;
        display: flex;
        text-align: center;

        @include media('<=tablet') {
          flex-direction: column;
      }

        a {
            height: 72px;
            flex: 1 1 33%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $dark-blue;
            text-decoration: none;

            @extend .p-large;


            @include media('<=tablet') {
              padding: 25px;
            }

            &:first-child {
             // background-color: $teal;
            }

            &:nth-child(2) {
              //background-color: $tn-green;
            }

            &:last-child {
              //background-color: $tissue-bank-blue;
            } 
        }
     
    }

  }
}