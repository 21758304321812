// color classes
.has-text-color {

    &.has-darkblue-color {color: $dark-blue;}
    &.has-teal-color {color: $teal;}
    &.has-blue-color {color: $tissue-bank-blue;}
    &.has-green-color {color: $tn-green;}
    &.has-white-color {color: #fff}
    &.has-grey-color {color: #666}
    &.has-light-yellow-color {color: #F9D85B}
    &.has-dark-yellow-color {color: #F1CA33}
    &.has-brown-grey-color {color: $brown-gray}

    // New Mexico
    &.has-nm-orange-color {color: $nm-orange}
    &.has-grenadier-color {color: $grenadier}
    &.has-pale-peach-color {color: $pale-peach}
    &.has-rose-peach-color {color: $rose-peach}
    &.has-teal-blue-color {color: $teal-blue}

    // DCIDS
    &.has-purple-color {color: $purple;}
    &.has-dark-purple-color {color: $dark-purple;}
    &.has-peach-color {color: $peach;}
    &.has-lavender-color {color: $lavender;}
    &.has-pale-blue-color {color: $pale-blue;}
    &.has-birth-tissue-purple-color {color: $birth-tissue-purple;}
    //DCIDSCAREERS
    &.has-career-blue-color {color: $career-blue; }
    &.has-career-faded-gray-color {color: $career-faded-gray; }
    &.has-career-pale-blue-color {color: $career-pale-blue; }
}

.has-background {
    &.has-darkblue-background-color {background-color: $dark-blue;}
    &.has-teal-background-color {background-color: $teal;}
    &.has-blue-background-color {background-color: $tissue-bank-blue;}
    &.has-green-background-color {background-color: $tn-green;}
    &.has-pink-background-color {background-color: $birth-tissue-pink;}
    &.has-brown-grey-background-color {background-color: $brown-gray;}
    &.has-green-stripes-background-color {
        background-image:
        linear-gradient(
          to top, 
          $tn-green,
          $tn-green 30%,
          #9FC86C 30%,
          #9FC86C 70%,
          $tn-green 70%
        );
    }

    &.has-light-grey-background-color {background-color: #C3CDD3;}
    &.has-dark-grey-background-color {background-color: #9EACB5;}
    &.has-grey-stripes-background-color {
        background-image:
        linear-gradient(
          to top, 
          #C3CDD3,
          #C3CDD3 30%,
          #9EACB5 30%,
          #9EACB5 70%,
         #C3CDD3 70%
        );
    }
    &.has-bright-blue-background-color {background-color: #00A6DF;}
    &.has-light-blue-background-color {background-color: $light-blue;}
    &.has-nm-orange-background-color {background-color: $nm-orange;}
    &.has-grenadier-background-color {background-color: $grenadier;}
    &.has-pale-peach-background-color {background-color: $pale-peach;}
    &.has-rose-peach-background-color {background-color: $rose-peach;}
    &.has-teal-blue-background-color {background-color: $teal-blue;}
    &.has-peach-stripes-background-color {
        background-image:
        linear-gradient(
          to top, 
          $pale-peach,
          $pale-peach 30%,
            $rose-peach 30%,
            $rose-peach 70%,
          $pale-peach 70%
        );
    }

    // DCIDS
    &.has-purple-background-color {background-color: $purple;}
    &.has-dark-purple-background-color {background-color: $dark-purple;}
    &.has-dark-peach-background-color {background-color: $peach;}
    &.has-lavender-background-color {background-color: $lavender;}
    &.has-purple-stripes-background-color {
        background-image:
        linear-gradient(
          to top, 
          $purple,
          $purple 30%,
            $dark-purple 30%,
            $dark-purple 70%,
          $purple 70%
        );
    }
    &.has-pale-blue-background-color {background-color: $pale-blue;}
    &.has-birth-tissue-purple-background-color {background-color: $birth-tissue-purple;}

    //DCIDS CAREERS
    &.has-career-blue-background-color {background-color: $career-blue; }
    &.has-career-faded-gray-background-color {background-color: $career-faded-gray; }
    &.has-career-pale-blue-background-color {background-color: $career-pale-blue; }
  
}



/* GRADIENTS */

.has-pink-to-white-gradient-background{
  background:linear-gradient(179deg, #FFF -1.96%, #FFF 11.75%, #FFF 24.93%, #E4BEEA 84.49%) !important;
}






/*
* Accessibility 
*/

a[target="_blank"]:after {
    content: " (opens in new window)";
    @extend .screenreader-text;
}

.screenreader-text{ // read text to screen readers
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
    word-wrap: normal !important;
  }

.wp-block-image {
    margin-bottom: 0 !important;
}

.aligncenter {
    margin-left: auto !important;
    margin-right: auto !important;
}

/* WP video embed 
.wp-block-embed-youtube .wp-block-embed__wrapper,
.wp-block-embed-vimeo .wp-block-embed__wrapper {
    max-width: 1020px;
    margin: 0 auto;
    padding-bottom: 56.25%;
    padding-top: 25px;
  }
  article .wp-block-embed-youtube .wp-block-embed__wrapper,
  article .wp-block-embed-vimeo .wp-block-embed__wrapper {
    max-width: inherit;
  }
  .wp-block-embed__wrapper {
    position: relative;
  }
  .wp-block-embed iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .wp-block-embed iframe {
    max-width: 100%;
  }

  */

  article {
    .wp-block-embed-youtube,
    .wp-block-embed-vimeo {

        &.wp-block-embed iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            max-width: 100%;
          }

        .wp-block-embed__wrapper {
            max-width: 1020px;
            margin: 0 auto;
            padding-bottom: 56.25%;
            padding-top: 25px;

            max-width: inherit;
            position: relative;

        }

    }
  }

  .wp-block-columns:not(.is-not-stacked-on-mobile) > .wp-block-column {
    margin-right: 1em;
  }


  /** Ninja Form Styles **/

  // Contact Form



.disclaimer {

    max-width: 1000px !important;
    margin: 0 auto;
    @extend .caption;
    }

    a {
    color: $dark-blue;
    }

    .desktop-only {
    display: block;

    @include media('<=tablet') {
        display: none;
    }
    }

    .mobile-only {
        display: none;

        @include media('<=tablet') {
            display: block;
        }
    }

    .wp-block-columns {
        margin-bottom: 0 !important;

        div:first-child {
            //margin-right: 2em;
        }
    }

    .wp-block-separator {
        color: #d4d4d4 !important;
        height: 2px !important;
        width: 100% !important;
    }

    .img-cover {

        img {
            height: 330px !important;
            object-fit: cover;
        }
    }

// wp-gallery

figure.wp-block-gallery.has-nested-images {
    display: flex;
    flex-wrap: wrap;
}

.wp-block-gallery.has-nested-images.is-cropped figure.wp-block-image:not(#individual-image) img {
    border-radius: 6px;
    transition: all ease-in-out 0.3s;

    &:hover {
        transform: scale(1.1);
        position: relative;
        z-index: 2;
    }
}


.nobottommargin{
  margin-bottom: 0;
}
.notopmargin{
  margin-top:0;
}


