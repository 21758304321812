// BUTTONS

.btn {
    $min_font: 1rem;
    $max_font: 1.25rem;
    
    @include fluid-type($min_width, $max_width, $min_font, $max_font);
    font-family: $f-secondary;
    font-weight: 400;
    color: $dark-blue;
    text-decoration: none;
    display: inline-block;
    margin-top: 25px;
    text-align: center;




    &--primary,
    &--is-style-lg {
        padding: 0.5rem 1.5rem;
        border-radius: 26px;
        box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
        border: solid 3px transparent;
        background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(89.7deg, #6AC8AB 3.12%, #1E96FC 99.7%);
        background-origin: border-box;
        background-clip: content-box, border-box;
        box-shadow: 2px 1000px 1px #fff inset;

        &:hover {
            box-shadow: none;
            color: white;
        }

        // Sierra theme
        &.sierra {
            background-image: unset;
            background-clip: border-box;

            &:hover {
                box-shadow: none;
                background-color: #AACA6F;
                color: white;
            }
        }
    }

    &--green-gradient {
        padding: 0.5rem 1.5rem;
        border-radius: 26px;
        box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
        border: solid 3px transparent;
        background-image: linear-gradient(89.77deg, #AACA6F 0.2%, #F9D85B 98.11%);
        background-origin: border-box;
        background-clip: content-box, border-box;
        box-shadow: 2px 1000px 1px #fff inset;

        &:hover {
            box-shadow: none;
            color: white;
        }
    }
    


    &--green,
    &--is-style-green {
        padding: 0.5rem 1.5rem;
        border-radius: 26px;
        box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
        background-color: white;
        border: solid 2px #AACA6F;
        box-shadow: 2px 1000px 1px #fff inset;

        &:hover {
            box-shadow: none;
            color: white;
            background-color: #AACA6F;
        }
    }


    &--small, 
    &--is-style-sm {
        padding: 0.5rem 1.25rem;
        border-radius: 26px;
        box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
        border: solid 3px transparent;
        background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(89.7deg, #6AC8AB 3.12%, #1E96FC 99.7%);
        background-origin: border-box;
        background-clip: content-box, border-box;
        box-shadow: 2px 1000px 1px #fff inset;
        font-size: 1.125rem;

        &:hover {
            box-shadow: none;
            color: white;
        }

        // Sierra theme
        &.sierra {
            background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(89.7deg, #6AC8AB 3.12%, #1E96FC 99.7%);

            &:hover {
                box-shadow: none;
                color: white;
            }
        }
    }

    &--arrow,
    &--is-style-arrow {
        width: 50%;
        max-width: 350px;
        height: 28px;
        position: relative;
        display: flex;
        align-items: center;

        $min_font: 1rem;
        $max_font: 1.5rem;
        
        @include fluid-type($min_width, $max_width, $min_font, $max_font);
        font-weight: 500;

        @include media('<=tablet') {
            width: 100%;
            max-width: 300px;
        }

        &:hover {

            &::after {
                right: -10px;
            }
        }

        &::after {
            position: absolute;
            right: 0;
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg width='14' height='28' viewBox='0 0 14 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 1L12 14L2 27' stroke='%236BA137' stroke-width='3'/%3E%3C/svg%3E");
            width: 14px;
            height: 28px;
            background-size: auto 100%;
            transition: right ease-in-out 0.5s;
        }
    }

    &--arrow-alt,
    &--is-style-arrow-alt {
        width: 50%;
        max-width: 350px;
        height: 28px;
        position: relative;
        display: flex;
        align-items: center;
        padding-left: 50px;

        $min_font: 0.875rem;
        $max_font: 0.875rem;
        
        @include fluid-type($min_width, $max_width, $min_font, $max_font);
        font-weight: 500;

        @include media('<=tablet') {
            width: 100%;
            max-width: 300px;
        }

        &:hover {

            &::after {
                left: -10px;
            }
        }

        &::after {
            position: absolute;
            left: 0;
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg width='14' height='28' viewBox='0 0 14 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 1L12 14L2 27' stroke='%2307314D' stroke-width='3'/%3E%3C/svg%3E");
            width: 14px;
            height: 28px;
            background-size: auto 100%;
            transition: left ease-in-out 0.5s;
            transform: rotate(180deg);
        }
    }


    &.btn--transparent {
        padding: 15px 51px;
        border-radius: 62px;
        color: white;
        border: 1px solid white;
        background: rgba(0, 0, 0, 0.40); 
        transition: all ease-in-out 0.25s;

        &:hover {
            background-color: white;
            color: black !important;
        }
    }
}

// Button Row
.btn__row {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    width: 100%;
  
    @media (max-width: 786px) {
      padding: 0 20px;
      flex-direction: column;
      align-items: center;
      max-width: inherit;
      justify-content: flex-start;
      margin: 0;
    }

    &.nm {
        .btn--primary {
            border: solid 2px $nm-orange;
            background-image: unset;
            background-clip: border-box;

            &:hover {
                border: solid 2px $grenadier;
                background-color: $grenadier;
            }
        }
    }

    .btn {
        &:not(:last-child) {
            margin-right: 33px;

            @media (max-width: 786px) {
                margin-right: 0;
              }
        }
    }
}


// Sierra theme buttons

body#sierra {
    .btn.btn--small,
    .btn.btn--primary,
    .btn.btn--is-style-sm,
    .btn.btn--is-style-lg {
        background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(89.7deg, #6AC8AB 3.12%, #1E96FC 99.7%);

        &:hover {
            box-shadow: none;
            color: white;
        }
    }
}

// New Mexico theme buttons

body#nm {
    .btn.btn--small,
    .btn.btn--primary,
    .btn.btn--is-style-sm,
    .btn.btn--is-style-lg {
        border: 3px solid $nm-orange;
        background-clip: border-box;
        background-image: unset;

        &:hover {
            box-shadow: none;
            color: white;
            border: solid 2px $grenadier;
            background-color: $grenadier;
            background-image: unset;
            background-clip: border-box;
        }
    }
}