.breadcrumbs {
    margin: 20px auto;
    max-width: 1440px;

    &.tds {

    }


    .breadcrumbs-wrapper {
        padding-left: 40px;
        display: flex;
        font-weight: 400;

        a {
            text-decoration: none;
            padding: 0 15px;

            &:not(:first-child) {
                padding-left: 0;
            }
        }
    }

    .breadcrumbs__link {
        text-decoration: none;
    }

    .breadcrumbs__current {
        padding-left: 15px;
        color: #666;
    }
}

