html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, pre,
a, abbr, acronym, address, big, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	border: 0;
	vertical-align: baseline;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

input[type=submit] {
     -webkit-appearance:none; 
}

img {
    max-width: 100%;
    height: auto;
    border: 0;
    vertical-align: bottom;
}

button {
    padding: 0;
    border: 0;
    border-radius: 0;
    background: none;
}

body {
    position: relative;
    height: 100%;
}

strong {
    font-weight: 900;
}