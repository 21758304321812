.logo-list {
    padding-left: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    &.1-column {
        @include media('<=tablet') {
            flex-direction: column;
        }
    }

    &.2-column {
        @include media('<=tablet') {
            display: grid;
            grid-template-columns: repeat( auto-fit, minmax(150px, 1fr) );
            grid-gap: 25px;
        }

        li {       
            
            &:not(:last-child) {

                @include media('<=tablet') {
                    margin-bottom: 0;
                }
        }

        }
    }

    li {
        list-style-type: none;
        display: flex;
        justify-content: center;

        &:not(:last-child) {
            margin-right: 30px;

            @include media('<=tablet') {
                margin-right: 0;
                margin-bottom: 25px;
            }
        }
        
        a {
            img {

            }
        }
    }
}