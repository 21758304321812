header {
    @include media('<=1250px') {
        position: sticky;
        top: 0;
        z-index: 1000;
        background-color: #ffffff;
    }
}

// Registration Banner
#sierra {
    .registration-banner {
        background: linear-gradient(180deg, #00A6DF 0%, #ACE0F2 100%);
    }
}
#tds{
  .registration-banner{
    background: linear-gradient(0deg, #7DC784 0%, #ABCC75 75%);
  }
  .registration-link{
    color: #07314D;
  }
}
#nm{
  .registration-banner{
    background: linear-gradient(to top, #D44214, #EA8667 75%);
  }
  .registration-link{
    color: #fff;
  }
}

.registration-banner {
    display: none;

    @include media('<=1250px') {
        padding: 10px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .registration-link {
        font-family: "Source Sans Pro";
        font-size: 16px;
        font-style: normal;
        font-weight: 900;
        line-height: normal; 
        color: #0A3550;
        text-decoration: none;
    }
}


// Top bar on every page
.top-bar-nav {
    padding: 0 50px;
    display: flex;
    justify-content: flex-end;
    background-color: $dark-blue;

    @include media('<=1250px') {
        display: none;
    }

    li {
        list-style-type: none;
        padding: 15px 20px 0;
        border-right: .25px solid #fff;

        &:first-child {
            border-left: .25px solid #fff;
        }

        &.phone,
        &.email {
            position: relative;
            border-left: none;
            padding-left: 10px;

            &::before {
                position: absolute;
                content: '';
            }
        }

        &.phone {
            border-right: none;
            padding-left: 54px;

            &::before {
                left: 24px;
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.142 17.3122L17.9728 15.3907C17.6601 14.877 17.1666 14.5155 16.5824 14.3737C15.9974 14.232 15.3944 14.3257 14.8806 14.6377L13.9154 15.2256C13.6206 15.4049 13.2494 15.3667 13.0116 15.1327C12.2429 14.3744 11.5687 13.5239 11.0084 12.6037C10.4474 11.682 10.0012 10.6912 9.68171 9.65847C9.5857 9.34801 9.72671 8.99471 10.0163 8.81769L10.9815 8.23043C12.0413 7.58543 12.3788 6.19863 11.733 5.13903L10.5638 3.21755C10.251 2.70379 9.75751 2.34231 9.17329 2.20051C8.58907 2.05801 7.98453 2.15255 7.47157 2.46527L5.60931 3.59855C4.93205 4.01027 4.52783 4.72503 4.52783 5.51105C4.52783 7.83906 4.98681 11.3649 7.16483 14.9431C8.14733 16.5578 10.38 19.5707 14.2944 21.6001C14.6342 21.7763 15.0002 21.864 15.3624 21.864C15.7674 21.864 16.1686 21.7545 16.5264 21.537L18.3893 20.403C18.9031 20.0903 19.2639 19.5968 19.4056 19.0133C19.5473 18.429 19.4535 17.8253 19.1408 17.3115L19.142 17.3122Z' fill='white'/%3E%3C/svg%3E%0A");
                width: 24px;
                height: 24px;
            }
        }

        &.email {
            padding-left: 56px;

            &::before {
                left: 16px;
                bottom: 22px;
                background-image: url("data:image/svg+xml,%3Csvg width='26' height='18' viewBox='0 0 26 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24.4884 0C25.3102 0 26 0.684622 26 1.49997V16.5C26 17.3155 25.3101 18 24.4884 18H1.51161C0.689775 18 -1.90735e-06 17.3154 -1.90735e-06 16.5V1.49997C-1.90735e-06 0.684463 0.689936 0 1.51161 0H24.4884ZM23.1279 1.80004H2.87188L12.7164 10.9967C12.8222 11.0957 13.1774 11.0957 13.2832 10.9967L23.1278 1.80004H23.1279ZM24.186 3.28123L17.8655 9.18741L24.186 15.0281V3.28123ZM1.81369 3.28123V15.0281L8.13417 9.18741L1.81369 3.28123ZM16.5429 10.4248L14.5306 12.3092C13.6643 13.1192 12.3359 13.1192 11.4695 12.3092L9.45723 10.4248L3.20277 16.2H22.7975L16.5429 10.4248Z' fill='white'/%3E%3C/svg%3E%0A");
                width: 26px;
                height: 18px;
            }
        }
    }

    a {
        margin-bottom: 7.5px;
        padding-bottom: 7.5px;
        display: inline-block;
        color: #fff;
        text-decoration: none;
        background: linear-gradient($tn-green, $tn-green) bottom/0% 2px no-repeat;
        background-size: 0% 2px;
        transition: 0.5s all;
        font-weight: 400;
        line-height: 37px;

        &:hover {
            background-size: 100% 3px;
        }

    }
}

// THEME color options

body#tds,
body#sierra,
body#nm {
    // Top bar Styles
    //for a campaign TDS: last link on the top-bar

    .top-bar-nav .top-bar-highlight,
    .top-bar-nav .top-bar-highlight a {
        background-color: $teal;
        color: $dark-blue;
    }

    .top-bar-nav .top-bar-highlight {
        border-right: 0;
        margin-right: -50px;
    }
}


body#sierra {

    // mobile nav button links
    .nav .nav-mobile .mobile li:nth-last-child(3) {
        background-color: #E0E8EE;
    }

    .nav .nav-mobile .mobile li:nth-last-child(2) {
        background-color: #AACC6F;
    }

    .nav .nav-mobile .mobile li:nth-last-child(1) {
        background-color: #00A6DF;
    }
}

body#nm {

    // mobile nav button links
    .nav .nav-mobile .mobile li:nth-last-child(3) {
        background-color: #E0E8EE;
    }

    .nav .nav-mobile .mobile li:nth-last-child(2) {
        background-color: #52C7BF;
    }

    .nav .nav-mobile .mobile li:nth-last-child(1) {
        background-color: #D54214;

        a:not(.sub-menu a) {
            color: white;
        }
    }
}

.nav {
    padding: 20px 50px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media('<=1300px') {
        padding: 20px 30px;
    }

    &.tds {
        .btn.btn--primary {
            background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(89.7deg, #6AC8AB 3.12%, #1E96FC 99.7%) !important;

            .nav-btn-list--item {
                z-index: 2;
                position: relative;
                background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(89.7deg, #6AC8AB 3.12%, #1E96FC 99.7%);
                text-align: center;
                width: 100%;
    
                a {
                    color: #fff;
                    background: linear-gradient(#fff, #fff) bottom/0% 2px no-repeat;
        
                    &:hover {
                        background-size: 100% 3px;
                    }
                }
            }
        }
    }

    &.sierra {
        .btn.btn--primary {
            padding: 0.5rem 1.5rem;
            border-radius: 100rem;
            background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(89.7deg, #6AC8AB 3.12%, #1E96FC 99.7%);


            .nav-btn-list--item {
                z-index: 2;
                position: relative;
                background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(89.7deg, #6AC8AB 3.12%, #1E96FC 99.7%);
                text-align: center;
                width: 100%;
    
                a {
                    color: #fff;
                    background: linear-gradient(#fff, #fff) bottom/0% 2px no-repeat;
        
                    &:hover {
                        background-size: 100% 3px;
                    }
                }
            }
        }
    }


    &.nm {
        .btn.btn--primary {
            padding: 0.5rem 1.5rem;
            border-radius: 100rem;
            border: 3px solid $nm-orange;
            background-clip: border-box;
            background-image: unset;

            &:hover {
                box-shadow: none;
                color: white;
                background-color: $grenadier;
                background-image: unset;
                background-clip: border-box;
            }


            .nav-btn-list--item {
                z-index: 2;
                position: relative;
                background-color: $grenadier;
                text-align: center;
                width: 100%;
    
                a {
                    color: #fff;
                    background: linear-gradient(#fff, #fff) bottom/0% 2px no-repeat;
        
                    &:hover {
                        background-size: 100% 3px;
                    }
                }
            }
        }

        .hamburger span {
            background: $nm-orange;
        } 

        .nav-links .navMain li::after {
            background-color: $nm-orange;
        }
    }
    
    &.dcids {
        .nav-links a {
            color: $dark-purple;
        }

        .nav-links .navMain li::after {
            background-color: $peach;
        }
    }

    @include media('<=tablet') {
        flex-direction: column-reverse;
    }

    &-logo {
        @include media('<=1250px') {
           margin-right: 5%;
        }

        img {
            width: 300px;
        }
    }

    // .nav-desktop
    &-desktop {
        flex: 1 1 auto;
        display: flex;
        justify-content: flex-end;

        @include media('<=1250px') {
            display: none;
        }
    }
    // .nav-links
    .nav-links {
        margin: 0 35px;

        .navMain {
            display: flex;
            padding-left: 0;

            li {
                list-style-type: none;
                position: relative;
                //padding-bottom: 15px;
                z-index: 55;
                

                &:not(:last-child) {
                    margin-right: 35px;
                }

                &::after {
                    position: absolute;
                    content: '';
                    bottom: -15px;
                    left: 50%;
                    z-index: 55;
                    transform: translate(-50%);
                    width: 0;
                    height: 2.5px;
                    background-color: #9FC86C;

                    transition: 0.5s all;
                }
    
                &:hover {
                    

                    &::after {
                        width: 100%;
                    }
                }

                &:hover .sub-menu {
                    max-height: 2000px;
                    overflow: visible;
                    height: auto;
                    padding: 25px 25px 5px;
                }

            }
        }

        a {
            color: $dark-blue;
            text-decoration: none;
            font-weight: 400;

            &:not(.sub-menu a) {
                line-height: 30px;
                padding-bottom: 100px;
            }
        }

        .sub-menu {
            position: absolute;
            z-index: 50;
            margin-top: 15px;
            text-align: center;
            //padding: 25px 25px 5px;
            left: 50%;
            transform: translate(-50%);
            width: 250px;
            background-color: $dark-blue;
            border-radius: 0 0 12px 12px;
            max-height: 0;
            overflow: hidden;
            display: block;

            li {
                margin-right: 0 !important;
                margin-bottom: 30px;
            }

            a {
                color: #fff !important;
            }
        }

    }

    ul.btn.btn {
        &:hover {
            border-radius: 26px 26px 0 0;
        }
    }



    
    // Header btn when it becomes a list
    .btn.btn--primary{
        text-transform: uppercase;
        margin-top: 0;
        border-radius: 26px;
        position: relative;

        @include media('<=1400px') {
            padding: 0.5rem 1.25rem;
            font-size: 1.125rem;
        }
        &:has(> .nav-btn-list) {
          &:hover {
              border-radius: 26px 26px 0 0;
          }
        }
        p {
            margin: 0;
        }

        &:hover .nav-btn-list {
            width: 103%;
            height: 100%;
            max-height: 2000px;
            overflow: visible;
            //background: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0)),linear-gradient(89.7deg, #AACA6F 3.12%, #F9D85B 99.7%);
            padding-left: 0;
            left: -2.5px;
        }

        .nav-btn-list {
            height: 0;
            max-height: 0;
            overflow: hidden;
            position: absolute;
            left: 0;
        }

        .nav-btn-list--item {
            z-index: 2;
            position: relative;
            text-align: center;
            width: 100%;

            &:not(:first-child) {
                border-radius: 0 0 26px 26px;
                padding: 0 0 25px 0;
            }

            &:first-child {
                padding-top: 25px;
            }

            &:not(:last-child) {
                border-radius: 5px 5px 0 0;
                padding-bottom: 12.5px;
            }

            a {
                color: $dark-blue;
                text-decoration: none;

                background: linear-gradient(#fff, #fff) bottom/0% 2px no-repeat;
                background-size: 0% 2px;
                transition: 0.5s all;
                padding-bottom: 15px;
                position: relative;
                z-index: 55;
    
                &:hover {
                    background-size: 100% 3px;
                }
            }
        }

        li {
            list-style-type: none;
        }

        @include media('<=1250px') {
            display: none;
        }
    }




    //hamburger nav animation
    @keyframes menu-hover {
        60% {
            transform: scaleX(1.4);
        }
        100% {
            transform: scaleX(1);
        }
    }

    .hamburger {
        display: flex;
        flex-direction: column;
        position: relative;

        @include media('>=1250px') {
            display: none;
        }

        @include media('<=tablet') {
            align-self: flex-end;
        }

        span {
            display: block;
            height: 8px;
            width: 48px;
            background: $tn-green;
            border-radius: 16px;

            @include media('<=tablet') {
                height: 5px;
                width: 30px;
            }

            // span:not(:last-child)
            &:not(:last-child) {
                margin-bottom: 10px;

                @include media('<=tablet') {
                    margin-bottom: 5px;
                }
            }

            // span:first-child
            &:first-child {
                width: 42px;
                transform: scaleX(1);
                opacity: 1;

                @include media('<=tablet') {
                    width: 24px;
                }
            }

            // span:nth-child(2)
            &:nth-child(2) {
                transform: scaleX(1);
                opacity: 1;
            }

            // span:last-child
            &:last-child {
                align-self: flex-end;
                width: 42px;
                transform: scaleX(1);
                opacity: 1;

                @include media('<=tablet') {
                    width: 24px;
                }
            }   
            

        }

        &:hover {
            cursor: pointer;
            span { animation: menu-hover .3s forwards cubic-bezier(0.0, 0.0, 0.2, 1) 1; }
            span:nth-child(2) { animation-delay: .1s; }
            span:last-child   { animation-delay: .2s; }
        }

        // spans turn into a "x"-close
        &.is-active {
            z-index: 6; 
            position: fixed;
            right: 30px;
            top: 60px;
        }

        &.is-active span{
            margin: 0;
            animation: unset;
            transition: all 0.3s ease-in-out;
        }


        &.is-active span:nth-child(1){
            transform: translate(0px, 0px) rotate(-45deg);

            @include media('<=tablet') {
                transform: translate(0px, -2px) rotate(-45deg);
            }
        }


        &.is-active span:nth-child(2){
            display: none;
        }

        &.is-active span:nth-child(3){
            width: 100%;
            transform: translate(0px, -7px) rotate(45deg);
        }
    }

    .page_overlay {
        display: none;
        &.open {

            @include media('<=1250px') {
                display: block;
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #7D7D7D;
                opacity: 0.75;
                z-index: 4;
            }
            
        }

      }

    .nav-mobile {
        width: 50%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: #fff;
        position: fixed;
        z-index: 5;
        top: 0;
        right: 0;

        opacity: 0;
        visibility: hidden;
        transition: opacity .35s, visibility .35s, width .35s;

        .top {
            padding: 38px 30px 20px;

            .btn {
                display: inline-block;
            }
        }

        .mobile li a {
            text-decoration: none;
            color: $dark-blue;
            background: linear-gradient($dark-blue, $dark-blue) bottom/0% 2px no-repeat;
            background-size: 0% 2px;
            transition: 0.5s all;
            padding: 5px;
    
            &:hover {
                background-size: 100% 3px;
            }
        }

        .mobile {
            margin: 0;
            padding-left: 0;
        }

        .mobile li {
            padding: 24px 40px;
            border-bottom: 1px solid $light-gray;
            list-style-type: none;

            &:nth-last-child(-n + 3) {
                border: none;
            }

            &:nth-last-child(3) {
                background-color: $light-blue;
            }

            &:nth-last-child(2) {
                background-color: $teal;
            }

            &:last-child {
                background-color: $tn-green;
            }

            // if link has a .sub-menu
            &.menu-item-has-children  {

                a{
                    position: relative;
                    margin-right: 30px !important;
        
                    &::after {
                        position: absolute;
                        right: -30px;
                        content: '';
                        background-image: url("data:image/svg+xml,%3Csvg width='14' height='28' viewBox='0 0 14 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 1L12 14L2 27' stroke='%2307314D' stroke-width='3'/%3E%3C/svg%3E");
                        width: 14px;
                        height: 28px;
                        background-size: auto 100%;
                        transition: transform ease-in-out 0.5s;
                    }
                }

                &:hover {

                    a::after {
                        transform: rotate(90deg);
                    }

                    .sub-menu {
                        padding-top: 15px;
                        max-height: 2000px;
                        overflow: visible;
                        height: auto;
                    }
                }

                .sub-menu {
                    a {
                        margin-right: 0 !important;
                        color: $dark-blue !important;
                        
                        &::after {
                            content: none !important;
                        }
                    }
                }

            }
        }

        .sub-menu {
            max-height: 0;
            overflow: hidden;
            transition: max-height, height ease-in-out .25s;

            li {
                padding: 12px 20px;
                background-color: inherit !important;
                border-bottom: none;

                &:last-child {
                    padding-bottom: 0;
                }
            }
        }

        .bottom {
            display: flex;
            align-items: center;
            flex: auto;
            background-color: $dark-blue;

            .col:first-child {
                img {
                    height: 180px;
                }
            }

            .col:last-child {
                margin-left: 25px;

                img {
                    height: 40px;
                }

                .social-links {
                    margin-top: 15px;
                    
                    a:not(:last-child) {

                        margin-right: 20px;
                    }

                    img {
                        height: 15px;
                    }
                }
            }
        }

        // when the menu is open
        &.open {

            @include media('<=1250px') {
                opacity: 1;
                visibility: visible;
                overflow: scroll;
                width: 50%;
                z-index: 5;
            }

            @include media('<=tablet') {
                width: 100%;
            }
        }
    }

}
