.columns {

    .columns-container {
        max-width: 1400px;
        margin: 0 auto;
        width: 100%;
        padding: 50px 25px 50px;
        display: flex;
        align-items: center;
        position: relative;


        @include media('<=tablet') {
            flex-direction: column;
            padding: 50px 5%;
        }
    }
}

/**
** Columns 40/60
**/

.columns-40-60 {
    &.text-highlight{
      //image background option on text-highlight
      background-position: top center;
      background-size: cover;
      background-image: var(--bg); //set at the tempalte level
    }

    &.nm {

        .col:first-child {
            border-right: 3px solid $nm-orange;

            @include media('<=tablet') {
                border-right: none;
                border-bottom: 3px solid $nm-orange;
            }
        }
    }
    &.dcicareers{
      .col:first-child {
        border-right: 3px solid $career-blue;

        @include media('<=tablet') {
            border-right: none;
            border-bottom: 3px solid $career-blue;
        }
      }
    }
    
    &.dcids {

        .col:first-child {
            border-right: 3px solid $peach;

            @include media('<=tablet') {
                border-right: none;
                border-bottom: 3px solid $peach;
            }
        }
    }

    .col:first-child {
        flex: 1 1 40%;
        margin-right: 44px;
        padding-right: 44px;
        border-right: 3px solid $tn-green;

        @include media('<=tablet') {
            flex: 1 1 100%;
            margin-right: 0;
            padding-right: 0;
            border-right: none;
            border-bottom: 3px solid $tn-green;
            padding-bottom: 44px;
            margin-bottom: 44px;
            width: 100%;
        }

        .heading {

            @include media('<=tablet') {
                max-width: 350px;
                margin: 0 auto;
            }
        }

        .heading-l {
            //color: #666;

            span {
                @extend .heading-xl;
                color: $dark-blue;
            }
        }
    }
    // :last-child was not working doing this for now
    .col:nth-child(2) {
        flex: 1 1 60%;

        @include media('<=tablet') {
            flex: 1 1 100%;
        }
    }
}

// reverse columns
.columns--reverse {

    .columns-container {
        flex-direction: row-reverse;

        @include media('<=tablet') {
            flex-direction: column;
        }
    }

    .col:first-child {
        margin-right: 0 !important;
        padding-right: 0 !important;
        border-right: none !important;
        margin-left: 44px;
        padding-left: 44px;
        border-left: 3px solid $tn-green;

        @include media('<=tablet') {
            margin-left: 0 !important;
            padding-left: 0 !important;
            border-left: none;
            border-bottom: 3px solid $tn-green;
        }
    }
} 