.header {
    display: flex; 
    justify-content: center;
    align-items: center;

    h1,h2, h3, h4, h5, h6, 
    .flex-xl,
    .heading-xl,
    .heading-l,
    .heading-sm,
    .heading-sm--bold {
       color: #666;
   
       span {
           color: $dark-blue;
       }
    }
}

/* homepage header 
*/

.header-hm,
.header-photo {
    position: relative;
    height: 68vh;
    overflow: hidden;

    @include media('<=1000px') {
        height: 100%;
        flex-direction: column;
    }

    // tds theme
    &.tds {
        background: linear-gradient(0deg, #29C0B4 13%, #7DC784 33%, #ABCC75 50%, #fff 94%);
    }
    // sierra theme 
    &.sierra {
        background: linear-gradient(0deg, rgba(56,176,220,1) 11%, rgba(166,223,244,1) 50%, rgba(255,255,255,1) 94%);
    }

    // NM theme
    &.nm {
        background: linear-gradient(180deg, #07314D 49.13%, #52C7BF 100%);

        .header-btn a span:first-child {
            border-bottom: 3px solid $nm-orange;
        }
    }

    &.dcids {
        background: linear-gradient(180deg, #706290 1.08%, #7082AB 15.44%, #B8CED7 43.42%, #B8CED7 41.66%, #7082AB 90.63%, #706290 100%);

        @include media('<=1000px') {
            background: linear-gradient(0deg, #706290 13%, #7082AB 33%, #706290 50%, #fff 94%);
        }

        .header-btn a span:first-child {
            border-bottom: 3px solid $peach;
        }
    }

    .header-col {
        width: 50%;
        color: $dark-blue;

        @include media('<=1000px') {
            width: 100%;
        }

        &:first-child {
            padding: 0 5%;
            overflow: hidden;



            > *:not(.header-btn) {
                position: relative;
                z-index: 3;
            }
        }

        &:last-child {
            img {
                display: none;

                @include media('<=1000px') {
                    display: block;
                    width: 100%;
                }
            }
        }
    }

    .header-col-bg {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: auto;
        height: 68vh;

        @include media('<=1000px') {
            display: none;
        }
    }

    .header-btn {
        position: absolute;
        content: '';
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0%);
        width: 250px;
        height: 150px;
        background-color: white;
        display: inline-block;
        border-top-left-radius: 125px;
        border-top-right-radius: 125px;

        @include media('<=1000px') {
            margin-top: 30px;
            width: 200px;
            position: relative;
        }

        a {
            @extend .heading-sm-header;
            width: inherit;
            height: inherit;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-align: center;
            color: $dark-blue;
            text-decoration:  none;
            position: relative;
            z-index: 3;

            &::before {
                position: absolute;
                content: '';
                background-image: url('../../assets/images/cropped-people-circle.svg');
                background-repeat: no-repeat;
                width: 375px;
                height: 310px;
                background-size: contain;
                opacity: 0.4;
                z-index: -7;

                @include media('<=1000px') {
                    background-size: 100% auto;
                    width: 300px;
                    height: 180px;
                    top: -30px;
                }
            }

            span:first-child {
                //width: 150px;
                margin-top: 25px;
                font-weight: 400;
                border-bottom: 3px solid $tn-green;
            }

            span:last-child {
                @extend .p-small;
                margin-top: 25px;
                margin-bottom: 0;
                text-decoration: none; 
            }
        }

    }
}

/* header extra small - news/events
*/
.header-x-sm {
  position: relative;
  padding: 50px 0;
  overflow: hidden;



  &::before {
      position: absolute;
      top: 0;
      right: -250px;
      content: '';
      background-image: var(--bg);
      background-repeat: no-repeat;
      z-index: 1;
      background-size: 100% auto;
      width: 500px;
      height: 500px;
      opacity: 0.3;

      @include media('<=tablet') {
          width: 200px;
          height: 200px;
          right: 50%;
          transform: translateY(-50%);
          bottom: -100px;
          top: unset;
          transform: translate(50%, 0%);
      }
  }
  &.tds {
    background: linear-gradient(0deg, #29C0B4 13%, #7DC784 33%, #ABCC75 50%, #fff 94%);
  }

  &.sierra {
    background: linear-gradient(0deg, rgba(56,176,220,1) 11%, rgba(166,223,244,1) 50%, rgba(255,255,255,1) 94%);
  }

  &.nm {
    background: linear-gradient(180deg, rgba(55,157,150,1) 37%, rgba(2,49,77,1) 82%);
  }

  .inner{
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    z-index: 5;//push copy above bg image in @before
    @include media('<=1200px') {
      padding: 0 3%;
    }
  }
}



/* header small 
*/
.header-sm {
    overflow: hidden;
    justify-content: flex-start;
    height: 40vh;
    position: relative;

    &.tds {
        background: linear-gradient(0deg, #29C0B4 13%, #7DC784 33%, #ABCC75 50%, #fff 94%);

        .header-col h2 {
            color: $dark-blue;
        }
    }

    &.sierra {
        background: linear-gradient(0deg, rgba(56,176,220,1) 11%, rgba(166,223,244,1) 50%, rgba(255,255,255,1) 94%);

        .header-col h2 {
            color: $dark-blue;
        }
    }

    &.nm {
        background: linear-gradient(180deg, rgba(55,157,150,1) 37%, rgba(2,49,77,1) 82%);
    } 

    &.dcids {
        background: linear-gradient(180deg, #706290 1.08%, #7082AB 15.44%, #B8CED7 43.42%, #B8CED7 41.66%, #7082AB 90.63%, #706290 100%);
        
        .header-col h2 {
            //color: white;
        }
    } 

    @include media('<=1000px') {
        justify-content: center;
    }

    &::before {
        position: absolute;
        top: 0;
        right: -250px;
        content: '';
        background-image: var(--bg);
        background-repeat: no-repeat;
        z-index: 1;
        background-size: 100% auto;
        width: 500px;
        height: 500px;
        opacity: 0.3;

        @include media('<=tablet') {
            width: 200px;
            height: 200px;
            right: 50%;
            transform: translateY(-50%);
            bottom: -100px;
            top: unset;
            transform: translate(50%, 0%);
        }
    }

    .header-col {
        padding: 25px 5%;
        max-width: 1400px;
        width: 50%;

        @include media('<=tablet') {
            padding: 0 5%;
        }

        &.align-left {
            //padding: 25px 5%;
        }

        &.align-center {
            padding: 0 5%;
            margin: 0 auto;
        }
    }
    /*
    .header-col:last-child {
        position: relative;
        opacity: 0.5;
        background-size: 100% auto;
        background-repeat: no-repeat;
        width: 50%;
        height: 80vh;
    } */
}


/* header photo https://tds.dev.dcids.org
*/

.header-photo {
    height: 31vw;
    overflow: hidden;

    @include media('<=1000px') {
        height: inherit;
        flex-direction: column;
    }

        // tds theme
        &.tds {
            background: linear-gradient(0deg, #29C0B4 13%, #7DC784 33%, #ABCC75 50%, #fff 94%);
        }
        // sierra theme 
        &.sierra {
            background: linear-gradient(0deg, rgba(56,176,220,1) 11%, rgba(166,223,244,1) 50%, rgba(255,255,255,1) 94%);
        }
        // nm theme
        &.nm {
            background: linear-gradient(180deg, rgba(55,157,150,1) 37%, rgba(2,49,77,1) 82%);
        }

    .header-col {
        
        &:first-child {
            @include media('<=1000px') {
                padding: 35px 5%;
            }
        }
    }

    .header-col-bg {

        @include media('<=1000px') {
            background-image: none;
        }

        img {
            display: none;

            @include media('<=1000px') {
                width: 100%;
                display: block;
            }
        }
    }
}

/* header plain */
.header-plain {
    padding: 26px 0 0;

    &.nm {
        .header-content {
            border-top: 1px solid $nm-orange;
            border-bottom: 1px solid $nm-orange;


        .header-col:first-child {
            border-right: 3px solid $nm-orange;

            @include media('<=1200px') {
                border-right: none;
                border-bottom: 3px solid $nm-orange;
            }
        }
        }

    }
    
    .header-content {
        display: flex;
        align-items: center;   
        width: 100%;
        padding: 50px 5%;
        border-top: 1px solid $tn-green;
        border-bottom: 1px solid $tn-green;

        @include media('<=1200px') {
            flex-direction: column;
        }
        
        .header-col:first-child {
            width: 40%;
            margin-right: 44px;
            padding-right: 44px;
            border-right: 3px solid $tn-green;
            text-align: right;

            @include media('<=1200px') {
                margin: 0 0 44px 0;
                padding: 0 0 44px 0;
                border-right: none;
                border-bottom: 3px solid #9FC86C;
                width: 100%;
            }

        }

        .header-col:last-child {
            width: 60%;

            @include media('<=1200px') {
                width: 100%;
            }
        }
    }

    .header-bottom {
        padding: 20px 5%;
        padding-left: 14%;
        width: 100%;

        a {
            color: $light-gray;
            text-decoration: none;

            &:not(:last-child) {
                margin-right: 5px;
                padding-right: 5px;
                border-right: 1px solid ;
                
            }

            &.active {
                color: $dark-blue !important;
            }
        }
    }
}

.header-photo--large {
    padding: 50px;
    position: relative;

    @include media('<=tablet') {
        padding: 0;
        flex-direction: column-reverse;
    }

    &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        background-image: linear-gradient(90deg, rgba(80,105,150,1) 58%, rgba(255,255,255,0) 67%);
        width: 100%;
        height: 100%;
        z-index: 2;
    }

    &::after {
        position: absolute;
        content: '';
        bottom: 0;
        right: 0;
        background-image: var(--bg-image);
        background-repeat: no-repeat;
        background-size: cover;
        width: 45%;
        height: 100%;
        z-index: 1;

        @include media('<=tablet') {
            background-image: none;
        }

    }

    .header-col {
        position: relative;
        width: 40%;
        z-index: 3;

        @include media('<=tablet') {
            width: 100%;
        }

        &:first-child {

            @include media('<=tablet') {
                padding: 50px 5%;
                background-color: #506996;
            }
        }

    }
}




/* header birth tissue 
*/
section.birthtissueheader{
  @include media('<=tablet') {
    &::after{//mobile image
      content: '';
      background-image: var(--mobileimage);
      display: block;
      height: 48vw;
      position: relative;
      width: 100%;
      background-position: center;
    }
  

  .has-text-align-left{text-align: left;}
  .has-text-align-center{text-align: center;}
  .has-text-align-right{text-align: right;}
  }
}

.header-bt {
  overflow: hidden;
    justify-content: flex-start;
    height: 36vw;
    position: relative;
    background: linear-gradient(90deg, rgba(229,182,237,0.8) .06%, rgba(217,217,217,0) 60%),var(--bg);
    background-size: 100%, cover;
    background-repeat: no-repeat;
    @include media('<=tablet') {
      background: linear-gradient(0deg, rgba(229,182,237,0.8) .06%, rgba(217,217,217,0) 60%);
      min-height: 48vw;
      height: auto;
    }

  &::before { //people circle
    position: absolute;
    top: 0;
    right: -150px;
    content: '';
    background-image: url(../images/People-Solid.svg);
    z-index: 1;
    background-size: contain;
    height: 100%;
    opacity: 0.8;
    width: 306px;
    background-position: center;
    background-repeat: no-repeat;

      @include media('<=tablet') {
          width: 200px;
          height: 200px;
          right: 50%;
          transform: translateY(-50%);
          bottom: -100px;
          top: unset;
          transform: translate(50%, 0%);
      }
  }

  .header-col {
      padding: 25px 5%;
      max-width: 1400px;
      width: 40%;
      z-index: 5;
      @include media('<=tablet') {
          padding: 5%;
          margin: 0 auto;
          width: 100%;
      }

      &.align-left {
          //padding: 25px 5%;
      }

      &.align-center {
          padding: 0 5%;
          margin: 0 auto;
      }
  }
}